import { ref } from "vue";

import {
  FieldConfigUnion,
  FieldUnion,
  RepeaterField,
  universalFieldUtils,
} from "@tager/admin-dynamic-field";

import {
  GenerationLandingFormValues,
  GenerationLandingValue,
} from "@/modules/generations/GenerationForm/landing/GenerationFormLanding.helpers";

export type AboutLandingFormValues = {
  aboutTitle?: string;
  aboutText?: string;
};

export const convertAboutValueToFormValues = (
  data: any
): AboutLandingFormValues => ({
  aboutTitle: data.aboutTitle,
  aboutText: data.aboutText,
});

export type AboutLandingValue = {
  aboutTitle?: string;
  aboutText?: string;
  aboutParams?: Array<{
    param: string;
    value: string;
  }>;
  aboutGallery?: Array<{
    image: string | null;
    video: string | null;
    videoUrl: string;
  }>;
  aboutTexts?: Array<{
    title: string;
    text: string;
  }>;
  aboutFeatures?: Array<{
    title: string;
    text: string;
    image: string | null;
  }>;
};

export const convertAboutFormValuesToValue = (
  formValues: GenerationLandingFormValues,
  aboutParamsField: RepeaterField,
  aboutGalleryField: RepeaterField,
  aboutTextsField: RepeaterField,
  aboutFeaturesField: RepeaterField
): AboutLandingValue => ({
  aboutTitle: formValues.aboutTitle,
  aboutText: formValues.aboutText,
  aboutParams: aboutParamsField.value.map((item) => ({
    param: String(item.value[0].value),
    value: String(item.value[1].value),
  })),
  aboutTexts: aboutTextsField.value.map((item) => {
    return {
      title: String(item.value[0].value),
      text: String(item.value[1].value),
    };
  }),
  aboutGallery: aboutGalleryField.value?.map((item) => {
    return {
      image: item.value[0].value ? (item.value[0].value as any).file.id : null,
      imageMobile: item.value[1].value
        ? (item.value[1].value as any).file.id
        : null,
      video: item.value[2].value ? (item.value[2].value as any).file.id : null,
      videoUrl: String(item.value[3].value),
    };
  }),
  aboutFeatures: aboutFeaturesField.value.map((item) => {
    return {
      title: String(item.value[0].value),
      text: String(item.value[1].value),
      image: item.value[2].value ? (item.value[2].value as any).file.id : null,
      imageMobile: item.value[3].value
        ? (item.value[3].value as any).file.id
        : null,
    };
  }),
});

export const AboutParamsConfig: FieldConfigUnion = {
  name: "",
  type: "REPEATER",
  label: "Параметры",
  meta: {
    view: "TABLE",
    noToggle: true,
  },
  fields: [
    {
      name: "param",
      type: "STRING",
      label: "Параметр",
    },
    {
      name: "value",
      type: "STRING",
      label: "Значение",
    },
  ],
};

export const createAboutParamsField = (data: GenerationLandingValue) =>
  ref<FieldUnion>(
    universalFieldUtils.createFormField(
      AboutParamsConfig,
      data.aboutParams?.map((item: any) => [
        {
          name: "param",
          value: item.param,
        },
        {
          name: "value",
          value: item.value,
        },
      ]) || []
    )
  );

export const AboutGalleryConfig: FieldConfigUnion = {
  name: "",
  type: "REPEATER",
  label: "Галерея",
  meta: {
    view: "BLOCK",
    noToggle: true,
  },
  fields: [
    {
      name: "image",
      type: "IMAGE",
      label: "Картинка (1230 x 450)",
      meta: {
        scenario: "generation-landing",
      },
    },
    {
      name: "imageMobile",
      type: "IMAGE",
      label: "Картинка мобильная",
      meta: {
        scenario: "generation-landing",
      },
    },
    {
      name: "videoFile",
      type: "FILE",
      label: "Видео - Файл",
      meta: {
        scenario: "generation-landing-video",
      },
    },
    {
      name: "videoUrl",
      type: "URL",
      label: "Видео - Ссылка",
    },
  ],
};

export const createAboutGalleryField = (data: GenerationLandingValue) =>
  ref<FieldUnion>(
    universalFieldUtils.createFormField(
      AboutGalleryConfig,
      data.aboutGallery?.map((item: any) => [
        {
          name: "image",
          value: item.image,
        },
        {
          name: "imageMobile",
          value: item.imageMobile,
        },
        {
          name: "video",
          value: item.video,
        },
        {
          name: "videoUrl",
          value: item.videoUrl,
        },
      ]) || []
    )
  );

export const AboutTextsConfig: FieldConfigUnion = {
  name: "",
  type: "REPEATER",
  label: "Текстовые блоки",
  meta: {
    view: "TABLE",
    noToggle: true,
  },
  fields: [
    {
      name: "title",
      type: "STRING",
      label: "Заголовок",
    },
    {
      name: "text",
      type: "TEXT",
      label: "Текст",
    },
  ],
};

export const createAboutTextsField = (data: GenerationLandingValue) =>
  ref<FieldUnion>(
    universalFieldUtils.createFormField(
      AboutTextsConfig,
      data.aboutTexts?.map((item: any) => [
        {
          name: "title",
          value: item.title,
        },
        {
          name: "text",
          value: item.text,
        },
      ]) || []
    )
  );

export const AboutFeaturesConfig: FieldConfigUnion = {
  name: "",
  type: "REPEATER",
  label: "Преимущества",
  meta: {
    view: "BLOCK",
    noToggle: true,
  },
  fields: [
    {
      name: "title",
      type: "STRING",
      label: "Заголовок",
    },
    {
      name: "text",
      type: "TEXT",
      label: "Текст",
    },
    {
      name: "image",
      type: "IMAGE",
      label: "Картинка (1080 x 430)",
      meta: {
        scenario: "generation-landing",
      },
    },
    {
      name: "imageMobile",
      type: "IMAGE",
      label: "Картинка мобильная",
      meta: {
        scenario: "generation-landing",
      },
    },
  ],
};

export const createAboutFeaturesField = (data: GenerationLandingValue) =>
  ref<FieldUnion>(
    universalFieldUtils.createFormField(
      AboutFeaturesConfig,
      data.aboutFeatures?.map((item: any) => [
        {
          name: "title",
          value: item.title,
        },
        {
          name: "text",
          value: item.text,
        },
        {
          name: "image",
          value: item.image,
        },
        {
          name: "imageMobile",
          value: item.imageMobile,
        },
      ]) || []
    )
  );
