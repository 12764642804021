import { OptionType } from "@tager/admin-ui";

export enum Gearbox {
  Manual = "MANUAL",
  Automatic = "AUTOMATIC",
  Robot = "ROBOT",
  DSG = "DSG",
  Variator = "VARIATOR",
  Reductor = "REDUCTOR",
}

const CarGearboxList: Array<{ id: Gearbox; label: string }> = [
  { id: Gearbox.Manual, label: "Механическая" },
  { id: Gearbox.Automatic, label: "Автоматическая" },
  { id: Gearbox.Robot, label: "Робот" },
  { id: Gearbox.DSG, label: "DSG" },
  { id: Gearbox.Variator, label: "Вариатор" },
  { id: Gearbox.Reductor, label: "Редуктор" },
];

export function getCarGearboxLabel(value: string | null): string {
  return (
    CarGearboxList.find((item) => item.id === value)?.label || "Неизвестный"
  );
}

const createOptionTypes = (items: Gearbox[]): Array<OptionType<Gearbox>> =>
  items.map((item) => ({ value: item, label: getCarGearboxLabel(item) }));

export const GearboxOptions: Array<OptionType<Gearbox>> = createOptionTypes([
  Gearbox.Manual,
  Gearbox.Automatic,
  Gearbox.Robot,
  Gearbox.DSG,
  Gearbox.Variator,
  Gearbox.Reductor,
]);
