import { Nullable } from "@tager/admin-services";

import {
  ParamsCategoryDetailedModel,
  ParamsCategorySavePayload,
} from "@/modules/params-categories/typings";

export interface ParamsCategoryFormValues {
  name: string;
}

export function getParamsCategoryFormValues(
  model: Nullable<ParamsCategoryDetailedModel>
): ParamsCategoryFormValues {
  return {
    name: model?.name || "",
  };
}

export function convertParamsCategoryFormValuesToSavePayload(
  values: ParamsCategoryFormValues
): ParamsCategorySavePayload {
  return {
    name: values.name,
  };
}
