import { Nullable } from "@tager/admin-services";
import { OptionType } from "@tager/admin-ui";

import { ParamType, ParamTypeOptions } from "@/modules/params/enums";
import { ParamDetailedModel, ParamSavePayload } from "@/modules/params/typings";

export interface ParamFormValues {
  category: Nullable<OptionType<number>>;
  name: string;
  type: ParamType;
  options: string;
}

export function getParamFormValues(
  model: Nullable<ParamDetailedModel>,
  categoryOptions: Array<OptionType<number>> = [],
  defaultCategoryId?: number | null
): ParamFormValues {
  return {
    category:
      categoryOptions.find(
        (item) =>
          item.value ===
          (model?.category.id ? model?.category.id : defaultCategoryId)
      ) || null,
    name: model?.name || "",
    type:
      ParamTypeOptions.find((item) => item.value === model?.type)?.value ||
      ParamTypeOptions[0].value,
    options: model?.options ? model.options.join("\n") : "",
  };
}

export function convertParamFormValuesToSavePayload(
  values: ParamFormValues
): ParamSavePayload {
  return {
    category: values.category?.value || null,
    name: values.name,
    type: values.type || null,
    options: values.options
      ? values.options.split("\n").filter((item) => item.trim().length > 0)
      : [],
  };
}
