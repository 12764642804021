import { compile } from "path-to-regexp";

import { ROUTE_PATHS } from "@/constants/paths";

export function getParamsListUrl(category: number): string {
  return compile(ROUTE_PATHS.PARAMS_LIST)({
    category: String(category),
  });
}

export function getParamsCreateUrl(params?: Record<string, string>) {
  return (
    compile(ROUTE_PATHS.PARAMS_FORM)({
      id: "create",
    }) + (params ? "?" + new URLSearchParams(params).toString() : "")
  );
}

export function getParamsUpdateUrl(id: number) {
  return compile(ROUTE_PATHS.PARAMS_FORM)({ id: String(id) });
}
