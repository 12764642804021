import { request, ResponseBody } from "@tager/admin-services";

import {
  ParamsCategoryDetailedModel,
  ParamsCategoryModel,
  ParamsCategorySavePayload,
} from "@/modules/params-categories/typings";

export function getParamsCategoriesList(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<ParamsCategoryModel>>> {
  return request.get({ path: "/admin/params-categories", params });
}

export function getParamsCategory(
  id: number
): Promise<ResponseBody<ParamsCategoryDetailedModel>> {
  return request.get({ path: `/admin/params-categories/${id}` });
}

export function createParamsCategory(
  body: ParamsCategorySavePayload
): Promise<ResponseBody<ParamsCategoryDetailedModel>> {
  return request.post({
    path: `/admin/params-categories`,
    body,
  });
}

export function updateParamsCategory(
  id: number,
  payload: ParamsCategorySavePayload
): Promise<ResponseBody<ParamsCategoryDetailedModel>> {
  return request.put({
    path: `/admin/params-categories/${id}`,
    body: payload,
  });
}

export function deleteParamsCategory(
  id: number | string
): Promise<{ success: boolean }> {
  return request.delete({
    path: `/admin/params-categories/${id}`,
  });
}

export function moveParamsCategory(
  id: number | string,
  direction: "up" | "down" | "up-top"
): Promise<{ success: boolean }> {
  return request.post({
    path: `/admin/params-categories/${id}/move/${direction}`,
  });
}
