import { request, ResponseBody } from "@tager/admin-services";

import {
  ParamDetailedModel,
  ParamModel,
  ParamSavePayload,
} from "@/modules/params/typings";

export function getParamsList(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
  filter?: any;
}): Promise<ResponseBody<Array<ParamModel>>> {
  return request.get({ path: "/admin/params", params });
}

export function getParam(
  id: number
): Promise<ResponseBody<ParamDetailedModel>> {
  return request.get({ path: "/admin/params/" + id });
}

export function createParam(
  body: ParamSavePayload
): Promise<ResponseBody<ParamDetailedModel>> {
  return request.post({
    path: `/admin/params`,
    body,
  });
}

export function updateParam(
  id: number,
  payload: ParamSavePayload
): Promise<ResponseBody<ParamDetailedModel>> {
  return request.put({
    path: `/admin/params/${id}`,
    body: payload,
  });
}

export function deleteParam(
  id: number | string
): Promise<{ success: boolean }> {
  return request.delete({
    path: `/admin/params/${id}`,
  });
}

export function moveParam(
  id: number | string,
  direction: "up" | "down" | "up-top"
): Promise<{ success: boolean }> {
  return request.post({
    path: `/admin/params/${id}/move/${direction}`,
  });
}
