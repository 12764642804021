import { ColumnDefinition } from "@tager/admin-ui";

import { ParamModel } from "@/modules/params/typings";
import { getParamTypeLabel } from "@/modules/params/enums";
import { getParamsUpdateUrl } from "@/modules/params/paths";

export const COLUMN_DEFS: Array<ColumnDefinition<ParamModel>> = [
  {
    id: 1,
    name: "ID",
    field: "id",
    style: { width: "80px", textAlign: "center" },
    headStyle: { width: "80px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 2,
    name: "Название",
    field: "name",
    type: "name",
    format: ({ row }) => ({
      adminLink: {
        url: getParamsUpdateUrl(row.id),
        text: row.name,
      },
    }),
  },
  {
    id: 3,
    name: "Тип",
    field: "type",
    format: ({ row }) => getParamTypeLabel(row.type),
  },
  {
    id: 10,
    name: "Действия",
    field: "actions",
    style: { width: "140px", textAlign: "center", whiteSpace: "nowrap" },
    headStyle: { width: "140px", textAlign: "center" },
  },
];
