import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditIcon = _resolveComponent("EditIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_DeleteIcon = _resolveComponent("DeleteIcon")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    "column-defs": _ctx.columnDefs,
    "row-data": _ctx.rowData,
    loading: _ctx.isRowDataLoading,
    "error-message": _ctx.errorMessage,
    "use-search": false
  }, {
    "cell(actions)": _withCtx(({ row }) => [
      _createVNode(_component_BaseButton, {
        variant: "icon",
        title: "Редактировать",
        disabled: _ctx.isBusy(),
        href: _ctx.getEngineUpdateUrl(row.id)
      }, {
        default: _withCtx(() => [
          _createVNode(_component_EditIcon)
        ]),
        _: 2
      }, 1032, ["disabled", "href"]),
      _createVNode(_component_BaseButton, {
        variant: "icon",
        title: "Удалить",
        disabled: _ctx.isBusy(row.id),
        onClick: ($event: any) => (_ctx.handleResourceDelete(row.id))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_DeleteIcon)
        ]),
        _: 2
      }, 1032, ["disabled", "onClick"])
    ]),
    _: 1
  }, 8, ["column-defs", "row-data", "loading", "error-message"]))
}