import { request, ResponseBody } from "@tager/admin-services";

import {
  EngineModel,
  EngineModelDetailed,
  EngineSavePayload,
} from "@/modules/engines/typings";

export function getEnginesList(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
  filter?: any;
}): Promise<ResponseBody<Array<EngineModel>>> {
  return request.get({ path: "/admin/engines", params });
}

export function getEngine(
  id: number
): Promise<ResponseBody<EngineModelDetailed>> {
  return request.get({ path: `/admin/engines/${id}` });
}

export function createEngine(
  body: EngineSavePayload
): Promise<ResponseBody<EngineModelDetailed>> {
  return request.post({
    path: `/admin/engines`,
    body,
  });
}

export function updateEngine(
  id: number,
  payload: EngineSavePayload
): Promise<ResponseBody<EngineModelDetailed>> {
  return request.put({
    path: `/admin/engines/${id}`,
    body: payload,
  });
}

export function deleteEngine(
  id: number | string
): Promise<{ success: boolean }> {
  return request.delete({
    path: `/admin/engines/${id}`,
  });
}
