<template>
  <Page :title="pageTitle" :is-content-loading="isContentLoading">
    <form v-if="!isContentLoading" novalidate @submit.prevent>
      <FormField
        v-model:value="values.name"
        name="name"
        :error="errors.name"
        label="Название"
      />
    </form>
    <template #footer>
      <FormFooter
        v-if="!isContentLoading"
        :back-href="backButtonUrl"
        :is-submitting="isSubmitting"
        :is-creation="isCreation"
        :can-create-another="true"
        @submit="submitForm"
      />
    </template>
  </Page>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  convertRequestErrorToMap,
  navigateBack,
  useResource,
  useToast,
} from "@tager/admin-services";
import { FormField, FormFooter, TagerFormSubmitEvent } from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";

import {
  convertParamsCategoryFormValuesToSavePayload,
  getParamsCategoryFormValues,
  ParamsCategoryFormValues,
} from "@/modules/params-categories/ParamsCategoryForm/ParamsCategoryForm.helpers";
import {
  createParamsCategory,
  getParamsCategory,
  updateParamsCategory,
} from "@/modules/params-categories/requests";
import {
  getParamsCategoryListUrl,
  getParamsCategoryUpdateUrl,
} from "@/modules/params-categories/paths";

export default defineComponent({
  name: "EngineForm",
  components: {
    FormField,
    Page,
    FormFooter,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const isSubmitting = ref<boolean>(false);
    const errors = ref<Record<string, string>>({});

    const id = computed(() => route.params.id as string | undefined);
    const isCreation = computed(() => id.value === "create");

    const values = ref<ParamsCategoryFormValues>(
      getParamsCategoryFormValues(null)
    );

    const [fetchModel, { data: model, loading: isModelLoading }] = useResource({
      fetchResource: () => {
        if (id.value && !isCreation.value) {
          return getParamsCategory(+id.value);
        }

        return Promise.resolve({ data: null });
      },
      initialValue: null,
      resourceName: "Params Category",
    });

    onMounted(() => {
      fetchModel();
    });

    watch(id, fetchModel);
    watch(
      model,
      () => (values.value = getParamsCategoryFormValues(model.value))
    );

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      const payload = convertParamsCategoryFormValuesToSavePayload(
        values.value
      );

      const requestPromise = isCreation.value
        ? createParamsCategory(payload)
        : updateParamsCategory(parseInt(id?.value || ""), payload);

      requestPromise
        .then((response) => {
          errors.value = {};

          if (event.type === "create") {
            router.push(getParamsCategoryUpdateUrl(response.data.id));
          }

          if (event.type === "create_exit" || event.type === "save_exit") {
            navigateBack(router, getParamsCategoryListUrl());
          }

          if (event.type === "create_create-another") {
            values.value = getParamsCategoryFormValues(null);
          }

          toast.show({
            variant: "success",
            title: "Категории",
            body: isCreation.value
              ? "Категория успешно создана"
              : "Категория успешно обновлена",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Категории",
            body: isCreation.value
              ? "Ошибка добавления категории"
              : "Ошибка изменения категории",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    const isContentLoading = computed<boolean>(() =>
      Boolean(isModelLoading.value)
    );

    const pageTitle = computed<string>(() => {
      if (isContentLoading.value) {
        return "Загрузка...";
      } else if (isCreation.value) {
        return `Новая категория`;
      } else {
        return `Редактирование категории # ${model.value?.id}`;
      }
    });

    return {
      submitForm,
      pageTitle,
      isContentLoading,
      model,

      backButtonUrl: getParamsCategoryListUrl(),
      isSubmitting,
      isCreation,

      errors,
      values,
    };
  },
});
</script>
