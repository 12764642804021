import { OptionType } from "@tager/admin-ui";

export enum EuroStandard {
  Euro4 = "EURO_4",
  Euro5 = "EURO_5",
  Euro6 = "EURO_6",
  Euro7 = "EURO_7",
  Euro8 = "EURO_8",
}

const EuroStandardList: Array<{
  id: EuroStandard;
  label: string;
}> = [
  { id: EuroStandard.Euro4, label: "Евро 4" },
  { id: EuroStandard.Euro5, label: "Евро 5 " },
  { id: EuroStandard.Euro6, label: "Евро 6" },
  { id: EuroStandard.Euro7, label: "Евро 7" },
  { id: EuroStandard.Euro8, label: "Евро 8" },
];

export function getEuroStandardLabel(value: EuroStandard): string {
  return (
    EuroStandardList.find((item) => item.id === value)?.label || "Неизвестный"
  );
}

const createOptionTypes = (
  items: EuroStandard[]
): Array<OptionType<EuroStandard>> =>
  items.map((item) => ({ value: item, label: getEuroStandardLabel(item) }));

export const EuroStandardOptions: Array<OptionType<EuroStandard>> =
  createOptionTypes([
    EuroStandard.Euro4,
    EuroStandard.Euro5,
    EuroStandard.Euro6,
    EuroStandard.Euro7,
    EuroStandard.Euro8,
  ]);
