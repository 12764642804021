import type { RouteRecordRaw } from "vue-router";

import { ROUTE_PATHS } from "@/constants/paths";
import { getLinks } from "@/constants/links";
import ParamsCategoriesList from "@/modules/params-categories/ParamsCategoriesList";
import ParamsCategoryForm from "@/modules/params-categories/ParamsCategoryForm";

export const PARAMS_CATEGORIES_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.PARAMS_CATEGORIES_LIST,
  component: ParamsCategoriesList,
  name: "Список категорий",
  meta: {
    getBreadcrumbs: (route, { t }) => [
      getLinks(t).HOME,
      {
        url: route.path,
        text: "Категории",
      },
    ],
  },
};

export const PARAMS_CATEGORIES_FORM_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.PARAMS_CATEGORIES_FORM,
  component: ParamsCategoryForm,
  name: "Форма категории",
  meta: {
    getBreadcrumbs: (route, { t }) => [
      getLinks(t).HOME,
      getLinks(t).PARAMS_CATEGORIES_LIST,
      {
        url: route.path,
        text: "Редактирование категории",
      },
    ],
  },
};
