<template>
  <Page :title="pageTitle" :is-content-loading="isContentLoading">
    <template #topRight>
      <ul class="breadcrumbs">
        <li v-for="item in breadcrumbs" :key="item.url">
          <RouterLink :to="item.url">{{ item.label }}</RouterLink>
        </li>
      </ul>
    </template>
    <form v-if="!isContentLoading" novalidate @submit.prevent>
      <FieldValue label="Поколение" :value="generationName" />
      <template v-if="isCustom">
        <FormField
          v-model:value="values.name"
          name="name"
          :error="errors.name"
          label="Название"
        />
      </template>
      <FormField
        v-model:value="values.subtitle"
        name="subtitle"
        :error="errors.subtitle"
        label="Подзаголовок"
      />
      <FormFieldFileInput
        v-model:value="values.image"
        :error="errors.image"
        label="Картинка (245 x 180)"
        name="image"
        file-type="image"
        scenario="complectation"
      />
      <FormFieldMultiSelect
        v-model:selected-options="values.engines"
        :disabled="isEnginesLoading"
        :options="engineOptions"
        label="Двигатели"
        :searchable="false"
      />
    </form>
    <template #footer>
      <FormFooter
        v-if="!isContentLoading"
        :back-href="backButtonUrl"
        :is-submitting="isSubmitting"
        :is-creation="isCreation"
        :can-create-another="true"
        @submit="submitForm"
      />
    </template>
  </Page>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  convertRequestErrorToMap,
  navigateBack,
  useResource,
  useToast,
} from "@tager/admin-services";
import {
  FormField,
  FieldValue,
  FormFieldFileInput,
  FormFooter,
  TagerFormSubmitEvent,
  getFilterParams,
  FormFieldMultiSelect,
  OptionType,
} from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";

import {
  getBrandFormUrl,
  getComplectationUpdateUrl,
  getGenerationFormUrl,
} from "@/utils/paths";
import {
  ComplectationCustomSavePayload,
  ComplectationSavePayload,
  createComplectation,
  getComplectation,
  getGeneration,
  updateComplectation,
} from "@/services/requests";
import {
  convertFormValuesToCustomSavePayload,
  convertFormValuesToSavePayload,
  FormValues,
  getComplectationFormValues,
} from "@/modules/complectations/ComplectationForm/ComplectationForm.helpers";
import { getEnginesList } from "@/modules/engines";
import { EngineModel } from "@/modules/engines/typings";

export default defineComponent({
  name: "ComplectationForm",
  components: {
    FormFieldMultiSelect,
    FormFieldFileInput,
    FormField,
    FieldValue,
    Page,
    FormFooter,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const isSubmitting = ref<boolean>(false);
    const isModelCatalogLoading = ref<boolean>(false);

    const id = computed(() => route.params.id as string | undefined);

    const errors = ref<Record<string, string>>({});
    const isCreation = computed(() => id.value === "create");

    const queryGenerationId = computed(() => {
      return isCreation.value
        ? (route.query.generation as string | undefined)
        : undefined;
    });

    const generationId = computed<number | undefined>(() => {
      if (isCreation.value) {
        return queryGenerationId.value ? +queryGenerationId.value : undefined;
      } else {
        return model.value?.generation?.id || undefined;
      }
    });

    const values = ref<FormValues>(getComplectationFormValues(null));

    const [fetchModel, { data: model, loading: isModelLoading }] = useResource({
      fetchResource: () => {
        if (id.value && !isCreation.value) {
          return getComplectation(+id.value);
        }

        return Promise.resolve({ data: null });
      },
      initialValue: null,
      resourceName: "Complectation",
    });

    const [
      fetchGeneration,
      { data: generation, loading: isGenerationLoading },
    ] = useResource({
      fetchResource: () => {
        if (generationId.value) {
          return getGeneration(+generationId.value);
        }

        return Promise.resolve({ data: null });
      },
      initialValue: null,
      resourceName: "Generation",
    });

    const [fetchEngines, { data: engines, loading: isEnginesLoading }] =
      useResource<Array<EngineModel>>({
        fetchResource: () =>
          getEnginesList(
            getFilterParams({
              generation: generationId.value ?? 0,
            })
          ),
        initialValue: [],
        resourceName: "Generation Engines",
      });

    onMounted(() => {
      fetchModel();
      fetchGeneration();
    });

    const engineOptions = computed<Array<OptionType<number>>>(() => {
      return (
        engines.value?.map((item) => {
          return {
            value: item.id,
            label: item.name || "",
          };
        }) || []
      );
    });

    watch(generationId, () => {
      fetchGeneration();
    });
    watch(generation, fetchEngines);

    watch(id, fetchModel);
    watch(
      [model, engineOptions],
      () =>
        (values.value = getComplectationFormValues(
          model.value,
          engineOptions.value
        ))
    );
    watch(generationId, fetchEngines);

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      let payload = {};
      if (isCustom.value) {
        payload = convertFormValuesToCustomSavePayload(values.value);
      } else {
        payload = convertFormValuesToSavePayload(values.value);
      }

      payload = { ...payload, generation: generationId.value };

      const requestPromise = isCreation.value
        ? createComplectation(payload as ComplectationCustomSavePayload)
        : updateComplectation(
            parseInt(id?.value || ""),
            payload as ComplectationSavePayload
          );

      requestPromise
        .then((response) => {
          errors.value = {};

          if (event.type === "create") {
            router.push(getComplectationUpdateUrl(response.data.id));
          }

          if (event.type === "create_exit" || event.type === "save_exit") {
            navigateBack(router, backButtonUrl.value);
          }

          if (event.type === "create_create-another") {
            values.value = getComplectationFormValues(null);
          }

          toast.show({
            variant: "success",
            title: "Локации",
            body: isCreation.value
              ? "Комплектация успешно создана"
              : "Комплектация успешно обновлена",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Локации",
            body: isCreation.value
              ? "Ошибка добавления комплектации"
              : "Ошибка изменения комплектации",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    const isContentLoading = computed<boolean>(
      () =>
        Boolean(isModelLoading.value) ||
        isGenerationLoading.value ||
        isModelCatalogLoading.value
    );

    const pageTitle = computed<string>(() => {
      if (isContentLoading.value) {
        return "Загрузка...";
      }
      if (isCreation.value) {
        return "Новая комплектация";
      }
      return `Редактирование комплектации # ${model.value?.id}`;
    });

    const isCustom = computed<boolean>(
      () => isCreation.value || !model.value?.apiId
    );

    watch(model, () => {
      values.value = getComplectationFormValues(model.value);
    });

    const backButtonUrl = computed<string>(() =>
      getGenerationFormUrl({
        generationId: String(generationId.value),
      })
    );

    const breadcrumbs = computed<Array<{ url: string; label: string }>>(() => {
      if (!generation.value) {
        return [];
      }

      const result = [];

      if (generation.value.brand?.id) {
        result.push({
          url: getBrandFormUrl({ brandId: generation.value.brand.id }),
          label: generation.value.brand.name,
        });
      }

      result.push({
        url: getGenerationFormUrl({ generationId: generation.value.id }),
        label: generation.value.name,
      });

      return result;
    });

    const generationName = computed<string>(() => {
      if (!generation.value) {
        return "Загрузка...";
      }

      return (
        [
          generation.value.brand?.name || "",
          generation.value.model?.name || "",
        ].join(" ") +
        ": " +
        generation.value.name
      );
    });

    return {
      submitForm,
      pageTitle,
      isContentLoading,
      model,

      backButtonUrl,
      isSubmitting,

      errors,
      values,

      isCustom,
      generationName,
      engineOptions,
      isCreation,
      isEnginesLoading,
      breadcrumbs,
    };
  },
});
</script>

<style scoped lang="scss">
.breadcrumbs {
  display: flex;
  gap: 0.3rem;

  li {
    position: relative;
    &:not(:last-child) {
      &:after {
        content: "-->";
        margin-left: 0.3rem;
      }
    }
  }
}
</style>
