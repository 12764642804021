import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FormFooter = _resolveComponent("FormFooter")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    title: _ctx.pageTitle,
    "is-content-loading": _ctx.isContentLoading
  }, {
    footer: _withCtx(() => [
      (!_ctx.isContentLoading)
        ? (_openBlock(), _createBlock(_component_FormFooter, {
            key: 0,
            "back-href": _ctx.backButtonUrl,
            "is-submitting": _ctx.isSubmitting,
            "is-creation": _ctx.isCreation,
            "can-create-another": true,
            onSubmit: _ctx.submitForm
          }, null, 8, ["back-href", "is-submitting", "is-creation", "onSubmit"]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      (!_ctx.isContentLoading)
        ? (_openBlock(), _createElementBlock("form", {
            key: 0,
            novalidate: "",
            onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
          }, [
            _createVNode(_component_FormField, {
              value: _ctx.values.name,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values.name) = $event)),
              name: "name",
              error: _ctx.errors.name,
              label: "Название"
            }, null, 8, ["value", "error"])
          ], 32))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "is-content-loading"]))
}