import { createId, Nullable } from "@tager/admin-services";
import { OptionType, SingleFileInputValueType } from "@tager/admin-ui";
import { FieldConfigUnion, RepeaterField } from "@tager/admin-dynamic-field";

import { FullGenerationInterface } from "@/typings/model";
import { GenerationCreateOrUpdatePayload } from "@/services/requests";
import { GenerationLandingValue } from "@/modules/generations/GenerationForm/landing/GenerationFormLanding.helpers";

export interface FormValues {
  isNew: boolean;

  brand: Nullable<OptionType<number>>;
  model: Nullable<OptionType<number>>;
  kufarId: Nullable<OptionType<string>>;
  name: string;
  slug: string;
  badge: string;
  badgeHint: string;
  isPublic: boolean;

  publicUrl: Nullable<string>;
  reviewUrl: Nullable<string>;
  pricesHidden: boolean;
  vinNewHidden: boolean;
  deliveryDisabled: boolean;
  saleDisabled: boolean;
  saleDisabledText: Nullable<string>;

  previewShortDescription: string;
  previewFullDescription: string;
  previewImage: Nullable<SingleFileInputValueType>;
}

export function convertGenerationToFormValues(
  generation: Nullable<FullGenerationInterface>,
  brands: Nullable<Array<OptionType<number>>>,
  models: Nullable<Array<OptionType<number>>>,
  kufarOptions: Nullable<Array<OptionType<string>>>
): FormValues {
  return {
    isNew: generation?.isNew || false,

    slug: generation?.slug || "",

    brand: brands?.find((item) => item.value === generation?.brand?.id) || null,
    model: models?.find((item) => item.value === generation?.model?.id) || null,
    kufarId:
      kufarOptions?.find((item) => item.value === generation?.kufar?.id) ||
      null,
    name: generation?.name ?? "",
    badge: generation?.badge ?? "",
    badgeHint: generation?.badgeHint ?? "",

    isPublic: Boolean(generation?.isPublic) ?? false,
    publicUrl: generation?.publicUrl ?? "",
    reviewUrl: generation?.reviewUrl ?? "",
    pricesHidden: Boolean(generation?.pricesHidden) ?? false,
    vinNewHidden: Boolean(generation?.vinNewHidden) ?? false,
    deliveryDisabled: Boolean(generation?.deliveryDisabled) ?? false,

    saleDisabled: Boolean(generation?.saleDisabled) ?? false,
    saleDisabledText: generation?.saleDisabledText ?? "",

    previewShortDescription: generation?.preview.shortDescription || "",
    previewFullDescription: generation?.preview.fullDescription || "",
    previewImage: generation?.preview.image
      ? { id: createId(), file: generation.preview.image }
      : null,
  };
}

export function convertFormValuesToGenerationUpdatePayload(
  values: FormValues,
  imagesField: RepeaterField,
  galleryField: RepeaterField,
  landing: GenerationLandingValue,
  options: OptionRow[]
): GenerationCreateOrUpdatePayload {
  return {
    isNew: values.isNew,
    model: values.model?.value || null,
    name: values.name || null,
    slug: values.slug || null,

    images: imagesField.value.map((row) => {
      return {
        color: Number((row.value[0]?.value as OptionType).value) || null,
        images:
          (row.value[1]?.value as any[]).map((item) => {
            return item.file.id;
          }) || [],
      };
    }),

    galleryItems: galleryField.value.map((row) => {
      return {
        category: String(row.value[0].value),
        file: row.value[1].value
          ? (row.value[1].value as any).file?.id || null
          : null,
        videoUrl: String(row.value[2].value),
      };
    }),

    badge: values.badge,
    badgeHint: values.badgeHint,
    isPublic: values.isPublic,
    publicUrl: values.isPublic ? values.publicUrl : null,
    reviewUrl: values.reviewUrl,
    pricesHidden: values.pricesHidden,
    vinNewHidden: values.vinNewHidden,
    deliveryDisabled: values.deliveryDisabled,
    kufarId: values.kufarId?.value || null,
    kufarName: values.kufarId?.label || null,
    saleDisabled: values.saleDisabled,
    saleDisabledText: values.saleDisabled ? values.saleDisabledText : null,

    landing,

    previewShortDescription: values.previewShortDescription,
    previewFullDescription: values.previewFullDescription,
    previewImage: (values.previewImage as any)?.file?.id || null,

    options,
  };
}

export function getGenerationImagesFieldConfig(
  colorOptions: Array<OptionType<number>>
): FieldConfigUnion {
  return {
    name: "",
    type: "REPEATER",
    label: "Картинки",
    meta: {
      view: "BLOCK",
      noToggle: true,
      titleFormatter: (fields) => {
        return fields[0].value
          ? (fields[0].value as OptionType).label
          : "Без цвета";
      },
    },
    fields: [
      {
        name: "color",
        type: "SELECT",
        label: "Цвет",
        meta: {
          options: [{ value: "", label: "Без цвета" }, ...colorOptions],
        },
      },
      {
        name: "images",
        type: "GALLERY",
        label: "Картинки",
        meta: {
          scenario: "generation-image-s3",
        },
      },
    ],
  };
}

export const GENERATION_GALLERY_CONFIG: FieldConfigUnion = {
  name: "",
  type: "REPEATER",
  label: "Картинки",
  meta: {
    view: "TABLE",
    noToggle: true,
  },
  fields: [
    {
      name: "category",
      type: "STRING",
      label: "Категория",
    },
    {
      name: "image",
      type: "FILE",
      label: "Картинка",
      meta: {
        scenario: "generation-image-s3",
      },
    },
    {
      name: "videoUrl",
      type: "URL",
      label: "Ссылка на Видео",
    },
  ],
};

export type OptionRow = {
  paramId: number;
  values: Array<{
    complectationId: number;
    value: any;
  }>;
};
