<template>
  <Page
    v-model:tab-id="selectedTabId"
    :title="pageTitle"
    :is-content-loading="isContentLoading"
    :tabs="isContentLoading ? [] : tabList"
    :header-buttons="headerButton ? [headerButton] : []"
  >
    <form v-if="!isContentLoading" novalidate @submit.prevent>
      <div :style="{ display: selectedTabId === 'general' ? 'block' : 'none' }">
        <div v-if="isCustom">
          <div class="row-cols-2">
            <FormFieldSelect
              v-model:value="values.brand"
              name="brand"
              :error="errors.brand"
              label="Бренд"
              :options="brandOptions"
            />
            <FormFieldSelect
              v-model:value="values.model"
              name="model"
              :error="errors.model"
              label="Модель"
              :options="modelOptions"
              :disabled="!values.brand?.value"
            />
          </div>

          <FormField
            v-model:value="values.name"
            name="name"
            :error="errors.name"
            label="Название"
          />
        </div>
        <template v-else>
          <div class="info">
            <FieldValue
              label="API ID"
              type="text"
              :value="String(model?.apiId) ?? ''"
            />
            <FieldValue
              label="Бренд"
              type="text"
              :value="model?.brand?.name ?? ''"
            />
            <FieldValue
              label="Модель"
              type="text"
              :value="model?.model?.name ?? ''"
            />
            <FieldValue
              label="Поколение"
              type="text"
              :value="model?.name ?? ''"
            />
          </div>
        </template>

        <FormFieldUrlAliasInput
          id="slug"
          v-model:value="values.slug"
          name="slug"
          label="URL"
          :url-template="`https://atlantm.by/catalog/${model?.brand?.slug}/`"
          :error="errors.slug"
        />

        <hr />

        <FormFieldCheckbox
          v-model:checked="values.isNew"
          name="isNew"
          :error="errors.isNew"
          label="Новинка"
        />

        <FormFieldCheckbox
          v-model:checked="values.vinNewHidden"
          name="vinNewHidden"
          label="VIN скрыт на новых авто"
          :error="errors.vinNewHidden"
        />
        <FormFieldCheckbox
          v-model:checked="values.pricesHidden"
          name="pricesHidden"
          label="Не отображать цены"
          :error="errors.pricesHidden"
        />
        <FormFieldCheckbox
          v-model:checked="values.deliveryDisabled"
          name="deliveryDisabled"
          label="Поставки приостановлены"
          :error="errors.deliveryDisabled"
        />
        <FormFieldCheckbox
          v-model:checked="values.saleDisabled"
          name="saleDisabled"
          label="Недоступен к заказу"
          :error="errors.saleDisabled"
        />
        <FormField
          v-if="values.saleDisabled"
          v-model:value="values.saleDisabledText"
          name="saleDisabledText"
          :error="errors.saleDisabledText"
          label="Пояснение (Недоступен к заказу)"
        />

        <FormField
          v-model:value="values.reviewUrl"
          name="reviewUrl"
          :error="errors.reviewUrl"
          label="Ссылка на обзор"
        />
      </div>

      <div :style="{ display: selectedTabId === 'params' ? 'block' : 'none' }">
        <GenerationFormTechnicalParamsValues v-if="model" :model="model" />
      </div>

      <div :style="{ display: selectedTabId === 'content' ? 'block' : 'none' }">
        <ToggleSection label="Каталог">
          <FormFieldCheckbox
            v-model:checked="values.isPublic"
            name="isPublic"
            label="Показывать в каталоге"
            :error="errors.isPublic"
          />
          <template v-if="values.isPublic">
            <FormField
              v-if="values.isPublic"
              v-model:value="values.badge"
              name="badge"
              :error="errors.badge"
              label="Бейдж"
            />
            <FormField
              v-if="values.isPublic"
              v-model:value="values.badgeHint"
              name="badgeHint"
              :error="errors.badgeHint"
              label="Бейдж - Подсказка"
            />
            <FormField
              v-if="values.isPublic"
              v-model:value="values.publicUrl"
              name="publicUrl"
              :error="errors.publicUrl"
              label="Web URL"
            />
            <DynamicField v-if="imagesField" :field="imagesField" />
          </template>
        </ToggleSection>

        <ToggleSection label="Превью">
          <p>Отображается в карточке автомобиля и на странице бренда</p>
          <br />

          <FormField
            v-model:value="values.previewShortDescription"
            name="previewShortDescription"
            :error="errors.previewShortDescription"
            label="Короткое описание"
          />
          <FormField
            v-model:value="values.previewFullDescription"
            name="previewFullDescription"
            :error="errors.previewFullDescription"
            label="Полное описание"
            type="textarea"
          />

          <FormFieldFileInput
            v-model:value="values.previewImage"
            :error="errors.previewImage"
            label="Картинка"
            name="image"
            file-type="image"
            scenario="logo-s3"
          />
        </ToggleSection>

        <ToggleSection label="Галерея">
          <p>Отображается в карточке автомобиля</p>
          <br />
          <DynamicField v-if="galleryField" :field="galleryField" />
        </ToggleSection>
      </div>

      <div :style="{ display: selectedTabId === 'landing' ? 'block' : 'none' }">
        <GenerationFormLanding
          v-if="model"
          v-model:value="valuesLanding"
          :data="model.landing ?? {}"
        />
      </div>

      <div
        :style="{
          display: selectedTabId === 'complectations' ? 'block' : 'none',
        }"
      >
        <GenerationComplectations v-if="model" :id="model.id" />
      </div>

      <div
        :style="{
          display: selectedTabId === 'options' ? 'block' : 'none',
        }"
      >
        <GenerationOptions
          v-if="model"
          :id="model.id"
          v-model:value="options"
        />
      </div>

      <div
        :style="{
          display: selectedTabId === 'engines' ? 'block' : 'none',
        }"
      >
        <GenerationEngines v-if="model" :id="model.id" />
      </div>
    </form>
    <template #footer>
      <FormFooter
        v-if="!isContentLoading"
        :back-href="backButtonUrl"
        :is-submitting="isSubmitting"
        @submit="submitForm"
      />
    </template>
  </Page>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  convertRequestErrorToMap,
  FileType,
  Nullable,
  useResource,
  useToast,
} from "@tager/admin-services";
import {
  FieldValue,
  FormField,
  FormFieldCheckbox,
  FormFieldSelect,
  FormFooter,
  OptionType,
  TabType,
  TagerFormSubmitEvent,
  FormFieldUrlAliasInput,
  FormFieldMultiSelect,
  FormFieldFileInput,
  ToggleSection,
} from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";
import {
  DynamicField,
  FieldUnion,
  RepeaterField,
  RepeaterIncomingValue,
  universalFieldUtils,
} from "@tager/admin-dynamic-field";

import { FullGenerationInterface, KufarBrand } from "@/typings/model";
import {
  createGeneration,
  GenerationParamOption,
  getGeneration,
  getGenerationParamsOptions,
  getKufarData,
  updateGeneration,
} from "@/services/requests";
import {
  convertFormValuesToGenerationUpdatePayload,
  convertGenerationToFormValues,
  FormValues,
  GENERATION_GALLERY_CONFIG,
  getGenerationImagesFieldConfig,
  OptionRow,
} from "@/modules/generations/GenerationForm/GenerationForm.helpers";
import {
  getComplectationCreateUrl,
  getGenerationFormUrl,
  getGenerationListUrl,
} from "@/utils/paths";
import { useFetchBrands } from "@/hooks/useFetchBrands";
import { useFetchModels } from "@/hooks/useFetchModels";
import { useFetchColors } from "@/hooks/useFetchColors";
import { getEngineCreateUrl } from "@/modules/engines";
import { GenerationLandingValue } from "@/modules/generations/GenerationForm/landing/GenerationFormLanding.helpers";
import GenerationFormLanding from "@/modules/generations/GenerationForm/landing/GenerationFormLanding.vue";
import GenerationFormTechnicalParamsValues from "@/modules/generations/GenerationForm/containers/GenerationFormTechnicalParamsValues.vue";
import GenerationComplectations from "@/modules/generations/GenerationForm/containers/GenerationComplectations.vue";
import GenerationEngines from "@/modules/generations/GenerationForm/containers/GenerationEngines.vue";
import GenerationOptions from "@/modules/generations/GenerationForm/containers/GenerationOptions.vue";

export default defineComponent({
  name: "GenerationForm",
  components: {
    GenerationOptions,
    GenerationEngines,
    GenerationComplectations,
    GenerationFormTechnicalParamsValues,
    GenerationFormLanding,
    Page,
    DynamicField,
    FormFooter,
    FormField,
    FormFieldSelect,
    FormFieldCheckbox,
    FormFieldUrlAliasInput,
    FormFieldFileInput,
    FieldValue,
    ToggleSection,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const generationId = computed(() => route.params.generationId as string);
    const isCreation = computed(() => generationId.value === "create");
    const errors = ref<Record<string, string>>({});
    const isSubmitting = ref<boolean>(false);

    const options = ref<Array<OptionRow>>([]);

    const { data: brandList, loading: isBrandsListLoading } = useFetchBrands();

    const { data: modelList, loading: isModelsListLoading } = useFetchModels();

    const { data: colorList, loading: isColorListLoading } = useFetchColors();

    const [
      fetchParamsOptions,
      { loading: paramsOptionsLoading, data: paramsOptions },
    ] = useResource<GenerationParamOption[]>({
      fetchResource: () => getGenerationParamsOptions(),
      initialValue: [],
      resourceName: "Опции параметров",
    });

    const [fetchKufarData, { loading: kufarLoading, data: kufarData }] =
      useResource<KufarBrand[]>({
        fetchResource: () => getKufarData(),
        initialValue: [],
        resourceName: "Список Kufar",
      });

    const [
      fetchGeneration,
      { data: generation, loading: isGenerationLoading },
    ] = useResource<Nullable<FullGenerationInterface>>({
      fetchResource: () => {
        if (generationId.value && !isCreation.value) {
          return getGeneration(generationId.value);
        }

        return Promise.resolve({ data: null });
      },
      initialValue: null,
      resourceName: "Поколение",
    });

    const kufarOptions = computed<Array<OptionType<string>>>(() => {
      if (
        !kufarData.value ||
        !generation.value?.model?.kufarId ||
        !generation.value?.brand?.kufarId
      ) {
        return [];
      }

      const kufarBrand = kufarData.value.find(
        (item) => item.id === generation.value?.brand?.kufarId
      );

      if (!kufarBrand) {
        return [];
      }

      const kufarModel = kufarBrand.models.find(
        (item) => item.id === generation.value?.model?.kufarId
      );

      if (!kufarModel) {
        return [];
      }

      return kufarModel.generations.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    });

    const bodiesOptions = computed<Array<OptionType>>(
      () =>
        paramsOptions.value?.find((item) => item.param === "BODY")?.options ||
        []
    );

    const gearboxOptions = computed<Array<OptionType>>(
      () =>
        paramsOptions.value?.find((item) => item.param === "GEARBOX")
          ?.options || []
    );

    const wheelOptions = computed<Array<OptionType>>(
      () =>
        paramsOptions.value?.find((item) => item.param === "WHEEL")?.options ||
        []
    );

    const fuelOptions = computed<Array<OptionType>>(
      () =>
        paramsOptions.value?.find((item) => item.param === "FUEL")?.options ||
        []
    );

    const batteryOptions = computed<Array<OptionType>>(
      () =>
        paramsOptions.value?.find((item) => item.param === "BATTERY")
          ?.options || []
    );

    onMounted(() => {
      fetchGeneration();
      fetchKufarData();
      fetchParamsOptions();
    });

    const brandOptions = computed<Array<OptionType<number>>>(() =>
      brandList.value.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      })
    );

    const colorOptions = computed<Array<OptionType<number>>>(() =>
      colorList.value.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      })
    );

    const modelOptions = computed<Array<OptionType<number>>>(() =>
      modelList.value
        .filter((item) => {
          if (!values.value.brand?.value) return false;
          return item.brandId == values.value.brand?.value;
        })
        .map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        })
    );

    const values = ref<FormValues>(
      convertGenerationToFormValues(
        generation.value,
        null,
        null,
        kufarOptions.value
      )
    );

    const valuesLanding = ref<GenerationLandingValue>({});

    watch([generation, brandOptions, modelList, kufarOptions], () => {
      values.value = convertGenerationToFormValues(
        generation.value,
        brandOptions.value,
        modelOptions.value,
        kufarOptions.value
      );
    });

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      const updateBody = convertFormValuesToGenerationUpdatePayload(
        values.value,
        imagesField.value as RepeaterField,
        galleryField.value as RepeaterField,
        valuesLanding.value,
        options.value
      );

      const requestPromise = isCreation.value
        ? createGeneration(updateBody)
        : updateGeneration(generationId.value, updateBody);

      requestPromise
        .then((response) => {
          errors.value = {};

          if (event.type === "create") {
            router.push(
              getGenerationFormUrl({ generationId: response.data.id })
            );
          }
          if (event.type === "create_exit" || event.type === "save_exit") {
            router.push(getGenerationListUrl());
          }
          if (event.type === "create_create-another") {
            values.value = convertGenerationToFormValues(null, null, null, []);
          }

          toast.show({
            variant: "success",
            title: "Success",
            body: "Поколение успешно обновлено",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Ошибка",
            body: "Ошибка при обновлении поколения",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    const isContentLoading = computed<boolean>(
      () =>
        Boolean(isGenerationLoading.value) ||
        Boolean(isBrandsListLoading.value) ||
        Boolean(isModelsListLoading.value) ||
        Boolean(kufarLoading.value) ||
        Boolean(paramsOptionsLoading.value)
    );

    const pageTitle = computed<string>(() => {
      if (isCreation.value) {
        return "Новое поколение";
      }
      if (!generation.value) {
        return "Загрузка...";
      }
      return `Редактирование поколения "${
        generation.value?.brand?.name +
          " - " +
          generation.value?.model?.name +
          " - " +
          generation.value?.name || "...."
      }"`;
    });

    const boolToString = (bool: boolean): string => {
      return bool ? "Да" : "Нет";
    };

    const isCustom = computed<boolean>(
      () => isCreation.value || generation.value?.apiId === null
    );

    const galleryField = ref<FieldUnion>(
      universalFieldUtils.createFormField(GENERATION_GALLERY_CONFIG, [])
    );

    function updateGalleryField() {
      const incomingFieldList: RepeaterIncomingValue = generation.value
        ?.galleryItems
        ? generation.value?.galleryItems.map((value) => {
            return [
              {
                name: "category",
                value: value.category || "",
              },
              {
                name: "videoUrl",
                value: value.videoUrl || "",
              },
              {
                name: "image",
                value: value.file,
              },
            ];
          })
        : [];

      galleryField.value = universalFieldUtils.createFormField(
        GENERATION_GALLERY_CONFIG,
        incomingFieldList
      ) as RepeaterField;
    }

    watch([generation], () => updateGalleryField());
    watch([generation], () => {
      if (generation.value) options.value = generation.value.options;
    });

    const imagesField = ref<FieldUnion>();

    watch([colorOptions], () => {
      imagesField.value = universalFieldUtils.createFormField(
        getGenerationImagesFieldConfig(colorOptions.value),
        []
      );
    });

    function updateImagesField() {
      const incomingFieldList: RepeaterIncomingValue = generation.value?.images
        ? generation.value?.images.map((value) => {
            return [
              {
                name: "color",
                value: value.color || "",
              },
              {
                name: "images",
                value: value.images.map((item) => {
                  return {
                    file: item as FileType,
                    caption: null,
                  };
                }),
              },
            ];
          })
        : [];

      imagesField.value = universalFieldUtils.createFormField(
        getGenerationImagesFieldConfig(colorOptions.value),
        incomingFieldList
      ) as RepeaterField;
    }

    watch([generation, colorOptions], () => updateImagesField());

    const tabList = computed<Array<TabType>>(() => {
      return [
        {
          id: "general",
          label: "Основное",
        },
        {
          id: "params",
          label: "Тех. параметры",
        },
        {
          id: "content",
          label: "Контент",
        },
        {
          id: "landing",
          label: "Лендинг",
        },
        {
          id: "engines",
          label: "Двигатели",
        },
        {
          id: "complectations",
          label: "Комплектации",
        },
        {
          id: "options",
          label: "Опции",
        },
      ];
    });
    const selectedTabId = ref<string>(tabList.value[0].id);

    const headerButton = computed(() => {
      if (generation.value) {
        if (selectedTabId.value === "complectations") {
          return {
            text: "Новая комплектация",
            href: getComplectationCreateUrl({
              generation: String(generation.value.id),
            }),
          };
        } else if (selectedTabId.value === "engines") {
          return {
            text: "Новый двигатель",
            href: getEngineCreateUrl({
              generation: String(generation.value.id),
            }),
          };
        }
      }

      return null;
    });

    return {
      headerButton,
      tabList,
      selectedTabId,
      pageTitle,
      isContentLoading,
      values,
      errors,
      model: generation,

      brandOptions,
      modelOptions,

      submitForm,
      backButtonUrl: getGenerationListUrl(),
      isSubmitting,
      boolToString,

      isCustom,
      imagesField,
      galleryField,

      kufarOptions,
      valuesLanding,
      bodiesOptions,
      gearboxOptions,
      wheelOptions,
      fuelOptions,
      colorOptions,
      batteryOptions,

      options,
    };
  },
});
</script>

<style scoped lang="scss">
.info {
  display: flex;

  div {
    margin-right: 3rem;
  }
}

.row-inputs {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
}
</style>
