import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableCellStock = _resolveComponent("TableCellStock")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    "column-defs": _ctx.columnDefs,
    "row-data": _ctx.rowData,
    loading: _ctx.isRowDataLoading,
    "error-message": _ctx.errorMessage,
    pagination: {
      pageNumber: _ctx.pageNumber,
      pageCount: _ctx.pageCount,
      pageSize: _ctx.pageSize,
      disabled: _ctx.isRowDataLoading,
    },
    onChange: _ctx.handleChange
  }, {
    "cell(stock-new)": _withCtx(({ row }) => [
      _createVNode(_component_TableCellStock, {
        model: row.stockNew
      }, null, 8, ["model"])
    ]),
    "cell(stock-amp)": _withCtx(({ row }) => [
      _createVNode(_component_TableCellStock, {
        model: row.stockAmp
      }, null, 8, ["model"])
    ]),
    "cell(stock-electric)": _withCtx(({ row }) => [
      _createVNode(_component_TableCellStock, {
        model: row.stockElectric
      }, null, 8, ["model"])
    ]),
    "cell(stock-commercial)": _withCtx(({ row }) => [
      _createVNode(_component_TableCellStock, {
        model: row.stockCommercial
      }, null, 8, ["model"])
    ]),
    "cell(stock-hybrid)": _withCtx(({ row }) => [
      _createVNode(_component_TableCellStock, {
        model: row.stockHybrid
      }, null, 8, ["model"])
    ]),
    _: 1
  }, 8, ["column-defs", "row-data", "loading", "error-message", "pagination", "onChange"]))
}