<template>
  <DataTable
    :column-defs="columnDefs"
    :row-data="rowData"
    :loading="isRowDataLoading"
    :error-message="errorMessage"
    :pagination="{
      pageNumber,
      pageCount,
      pageSize,
      disabled: isRowDataLoading,
    }"
    @change="handleChange"
  >
    <template #cell(stock-new)="{ row }">
      <TableCellStock :model="row.stockNew" />
    </template>

    <template #cell(stock-amp)="{ row }">
      <TableCellStock :model="row.stockAmp" />
    </template>

    <template #cell(stock-electric)="{ row }">
      <TableCellStock :model="row.stockElectric" />
    </template>

    <template #cell(stock-commercial)="{ row }">
      <TableCellStock :model="row.stockCommercial" />
    </template>

    <template #cell(stock-hybrid)="{ row }">
      <TableCellStock :model="row.stockHybrid" />
    </template>
  </DataTable>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";

import { DataTable, getFilterParams, useDataTable } from "@tager/admin-ui";

import { ModelInterface } from "@/typings/model";
import { getModelList } from "@/services/requests";
import { getComplectationUpdateUrl } from "@/utils/paths";
import { getModelListColumnDefs } from "@/modules/models/ModelList/ModelList.helpers";
import TableCellStock from "@/components/TableCellStock.vue";

export default defineComponent({
  name: "BrandFormModels",
  components: {
    TableCellStock,
    DataTable,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const {
      fetchEntityList: fetchData,
      isLoading: isRowDataLoading,
      rowData: data,
      errorMessage,
    } = useDataTable<ModelInterface>({
      fetchEntityList: () =>
        getModelList(
          getFilterParams({
            brand: props.id,
          })
        ),
      initialValue: [],
      resourceName: "Brand Models List",
    });

    onMounted(() => {
      fetchData();
    });

    function isBusy(id: number): boolean {
      return isRowDataLoading.value;
    }

    return {
      columnDefs: getModelListColumnDefs("brand"),
      rowData: data,
      isRowDataLoading,
      isBusy,
      errorMessage: errorMessage,
      getComplectationUpdateUrl,
    };
  },
});
</script>
