<template>
  <Page
    title="Категории параметров"
    :header-buttons="[
      {
        text: 'Новая категория',
        href: getParamsCategoryCreateUrl(),
      },
    ]"
  >
    <DataTable
      :column-defs="columnDefs"
      :row-data="rowData"
      :loading="isRowDataLoading"
      :error-message="errorMessage"
      :search-query="searchQuery"
      :pagination="{
        pageNumber,
        pageCount,
        pageSize,
        disabled: isRowDataLoading,
      }"
      @change="handleChange"
    >
      <template #cell(actions)="{ row, totalRowIndex, totalCount }">
        <BaseButton
          variant="icon"
          :disabled="isBusy(row.id) || totalRowIndex === totalCount - 1"
          @click="handleResourceMove(row.id, 'down')"
        >
          <SouthIcon />
        </BaseButton>
        <BaseButton
          variant="icon"
          :disabled="isBusy(row.id) || totalRowIndex === 0"
          @click="handleResourceMove(row.id, 'up')"
        >
          <NorthIcon />
        </BaseButton>
        <BaseButton
          variant="icon"
          title="Переместить в самый верх"
          :disabled="isBusy(row.id) || totalRowIndex === 0"
          @click="handleResourceMove(row.id, 'up-top')"
        >
          <MoveTopIcon />
        </BaseButton>
        <BaseButton
          variant="icon"
          title="Редактировать"
          :disabled="isBusy(row.id)"
          :href="getParamsCategoryUpdateUrl(row.id)"
        >
          <EditIcon />
        </BaseButton>
        <BaseButton
          variant="icon"
          title="Удалить"
          :disabled="isBusy(row.id) || !row.canDelete"
          @click="handleResourceDelete(row.id)"
        >
          <DeleteIcon />
        </BaseButton>
      </template>
    </DataTable>
  </Page>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import {
  useDataTable,
  DataTable,
  BaseButton,
  EditIcon,
  SouthIcon,
  MoveTopIcon,
  NorthIcon,
  DeleteIcon,
} from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";
import { useResourceDelete, useResourceMove } from "@tager/admin-services";

import { COLUMN_DEFS } from "@/modules/params-categories/ParamsCategoriesList/ParamsCategoriesList.helpers";
import {
  deleteParamsCategory,
  getParamsCategoriesList,
  moveParamsCategory,
} from "@/modules/params-categories/requests";
import { ParamsCategoryModel } from "@/modules/params-categories/typings";
import {
  getParamsCategoryCreateUrl,
  getParamsCategoryUpdateUrl,
} from "@/modules/params-categories/paths";

export default defineComponent({
  name: "ParamsCategoriesList",
  components: {
    DeleteIcon,
    NorthIcon,
    MoveTopIcon,
    SouthIcon,
    DataTable,
    Page,
    BaseButton,
    EditIcon,
  },
  setup() {
    const {
      fetchEntityList,
      isLoading,
      rowData,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<ParamsCategoryModel>({
      fetchEntityList: (params) =>
        getParamsCategoriesList({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
        }),
      initialValue: [],
      resourceName: "Список категорий",
    });

    const { isMoving, handleResourceMove } = useResourceMove({
      moveResource: moveParamsCategory,
      resourceName: "Move Category",
      onSuccess: fetchEntityList,
    });

    const { isDeleting, handleResourceDelete } = useResourceDelete({
      deleteResource: deleteParamsCategory,
      resourceName: "Delete Category",
      onSuccess: fetchEntityList,
    });

    function isBusy(id: number): boolean {
      return isDeleting(id) || isMoving(id) || isLoading.value;
    }

    return {
      columnDefs: COLUMN_DEFS,
      rowData: rowData,
      isRowDataLoading: isLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      isBusy,

      handleResourceDelete,
      getParamsCategoryCreateUrl,
      handleResourceMove,
    };
  },
  methods: { getParamsCategoryUpdateUrl },
});
</script>
