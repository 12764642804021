import { OptionType } from "@tager/admin-ui";

export enum EngineType {
  Petrol = "PETROL",
  Diesel = "DIESEL",
  Gas = "GAS",
  Electric = "ELECTRIC",
  Hybrid = "HYBRID",
}

export const EngineTypeList: Array<{
  id: EngineType;
  label: string;
}> = [
  { id: EngineType.Petrol, label: "Бензин" },
  { id: EngineType.Diesel, label: "Дизель" },
  { id: EngineType.Gas, label: "Газ" },
  { id: EngineType.Electric, label: "Электро ☘️" },
  { id: EngineType.Hybrid, label: "Гибрид" },
];

export function getEngineTypeLabel(engineType: EngineType | null): string {
  return (
    EngineTypeList.find((item) => item.id === engineType)?.label ||
    "Неизвестный"
  );
}

const createOptionTypes = (
  items: EngineType[]
): Array<OptionType<EngineType>> =>
  items.map((item) => ({ value: item, label: getEngineTypeLabel(item) }));

export const EngineTypeOptions: Array<OptionType<EngineType>> =
  createOptionTypes([
    EngineType.Petrol,
    EngineType.Diesel,
    EngineType.Electric,
    EngineType.Hybrid,
    EngineType.Gas,
  ]);
