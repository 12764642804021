import type { RouteRecordRaw } from "vue-router";

import { ROUTE_PATHS } from "@/constants/paths";
import { getLinks } from "@/constants/links";
import ParamsList from "@/modules/params/ParamsList";
import ParamsForm from "@/modules/params/ParamsForm";

export const PARAMS_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.PARAMS_LIST,
  component: ParamsList,
  name: "Список параметров",
  meta: {
    getBreadcrumbs: (route, { t }) => [
      getLinks(t).HOME,
      getLinks(t).PARAMS_CATEGORIES_LIST,
      {
        url: route.path,
        text: "Новый параметр",
      },
    ],
  },
};

export const PARAMS_FORM_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.PARAMS_FORM,
  component: ParamsForm,
  name: "Форма параметров",
  meta: {
    getBreadcrumbs: (route, { t }) => [
      getLinks(t).HOME,
      getLinks(t).PARAMS_CATEGORIES_LIST,
      {
        url: route.path,
        text: "Редактирование параметра",
      },
    ],
  },
};
