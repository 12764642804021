import { OptionType } from "@tager/admin-ui";

export enum Wheel {
  Front = "FRONT",
  Back = "BACK",
  Full = "FULL",
}

export const CarWheelsList: Array<{ id: Wheel; label: string }> = [
  { id: Wheel.Front, label: "Передний" },
  { id: Wheel.Back, label: "Задний" },
  { id: Wheel.Full, label: "Полный" },
];

export function getCarWheelLabel(carWheel: string | null): string {
  return (
    CarWheelsList.find((item) => item.id === carWheel)?.label || "Неизвестный"
  );
}

const createOptionTypes = (items: Wheel[]): Array<OptionType<Wheel>> =>
  items.map((item) => ({ value: item, label: getCarWheelLabel(item) }));

export const WheelOptions: Array<OptionType<Wheel>> = createOptionTypes([
  Wheel.Front,
  Wheel.Back,
  Wheel.Full,
]);
