import { ColumnDefinition, OptionType } from "@tager/admin-ui";

import { GenerationInterface } from "@/typings/model";
import { getComplectationUpdateUrl, getGenerationFormUrl } from "@/utils/paths";
import { getEngineUpdateUrl } from "@/modules/engines";

export const stockFilterOptions: OptionType[] = [
  {
    label: "В наличии",
    value: "in_stock",
  },
  {
    label: "Нет в наличии",
    value: "no_stock",
  },
];
export const COLUMN_DEFS: Array<ColumnDefinition<GenerationInterface>> = [
  {
    id: 1,
    name: "API ID",
    field: "id",
    style: { width: "80px", textAlign: "center" },
    headStyle: { width: "80px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 2,
    name: "Название",
    field: "name",
    type: "name",
    format: ({ row }) => ({
      adminLink: {
        text: row.model + ": " + row.name,
        url: getGenerationFormUrl({ generationId: row.id }),
      },
      websiteLink: row.url
        ? {
            text: row.url,
            url: row.url,
          }
        : null,
      paramList: [
        { name: "Бренд", value: row.brand },
        { name: "Модель", value: row.model },
        {
          name: "Kufar",
          value: row.kufar ? `${row.kufar.id} (${row.kufar.name})` : "-",
        },
        { name: "Снят с продажи", value: row.saleDisabled ? "Да" : "Нет" },
        {
          name: "Не выводить VIN в NEW",
          value: row.vinNewHidden ? "Да" : "Нет",
        },
        {
          name: "Спрятать цены",
          value: row.pricesHidden ? "Да" : "Нет",
        },
      ],
    }),
  },
  {
    id: 3,
    name: "Наличие",
    field: "stock",
  },
  {
    id: 4,
    name: "Комплектации",
    field: "complectations",
    type: "list",
    format: ({ row }) => {
      return row.complectations.map((item) => ({
        url: getComplectationUpdateUrl(item.id),
        label: item.name,
      }));
    },
  },
  {
    id: 5,
    name: "Двигатели",
    field: "engines",
    type: "list",
    format: ({ row }) => {
      return row.engines.map((item) => ({
        url: getEngineUpdateUrl(item.id),
        label: item.name,
      }));
    },
  },
  {
    id: 6,
    name: "Действия",
    field: "actions",
    style: { width: "100px", textAlign: "center", whiteSpace: "nowrap" },
    headStyle: { width: "100px", textAlign: "center" },
  },
];
