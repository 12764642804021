<template>
  <DataTable
    :column-defs="columnDefs"
    :row-data="rowData"
    :loading="isRowDataLoading"
    :error-message="errorMessage"
    :pagination="{
      disabled: isRowDataLoading,
    }"
    @change="handleChange"
  >
    <template #cell(id)="{ row }">
      <div :class="[{ 'no-active': !row.isPublic }]">
        {{ row.apiId }}
      </div>
    </template>
    <template #cell(brand)="{ row }">
      <div :class="[{ 'no-active': !row.isPublic }]">
        {{ row.brand }}
      </div>
    </template>
    <template #cell(model)="{ row }">
      <div :class="[{ 'no-active': !row.isPublic }]">
        {{ row.model }}
      </div>
    </template>

    <template #cell(stock)="{ row }">
      <div :class="['stock', { 'no-active': !row.isPublic }]">
        <div v-if="row.stockQuantity > 0" class="stock-section">
          <span class="stock-section-title">В наличии:</span>
          <span class="stock-title">{{ row.stockQuantity }} авто</span>

          <span class="stock-price"
            >от {{ formatPrice(row.stockMinPrice) }} BYN
            <template v-if="row.stockMaxPrice">
              <br />до {{ formatPrice(row.stockMaxPrice) }} BYN
            </template>
          </span>
        </div>

        <div
          v-if="
            row.stockNewAvailableQuantity &&
            row.stockNewAvailableQuantity !== row.stockQuantity
          "
          class="stock-section"
        >
          <span class="stock-section-title">Доступно:</span>
          <span class="stock-title"
            >{{ row.stockNewAvailableQuantity }} авто</span
          >

          <span class="stock-price"
            >от {{ formatPrice(row.stockNewAvailableMinPrice) }} BYN
            <template v-if="row.stockNewAvailableMaxPrice">
              <br />до {{ formatPrice(row.stockNewAvailableMaxPrice) }} BYN
            </template>
          </span>
        </div>

        <div v-if="row.stockNewPreOrderQuantity > 0" class="stock-section">
          <span class="stock-section-title">Под заказ:</span>
          <span class="stock-title"
            >{{ row.stockNewPreOrderQuantity }} авто</span
          >
          <span class="stock-price"
            >от {{ formatPrice(row.stockNewPreOrderMinPrice) }} BYN
            <template v-if="row.stockNewPreOrderMaxPrice">
              <br />до {{ formatPrice(row.stockNewPreOrderMaxPrice) }} BYN
            </template>
          </span>
        </div>

        <div v-if="row.stockQuantity > 0" class="stock-link">
          <a :href="row.stockUrl" target="_blank">Склад</a>
        </div>
      </div>
    </template>

    <template #cell(website)="{ row }">
      <div :class="[{ 'no-active': !row.isPublic }]">
        <div v-if="!row.isPublic">Не отображается</div>
        <div v-else>
          <div v-if="!row.url">Активно</div>
          <div v-else>
            <a :href="row.url" target="_blank">{{ row.url }}</a>
          </div>
        </div>
      </div>
    </template>

    <template #cell(actions)="{ row, rowIndex }">
      <BaseButton
        variant="icon"
        :disabled="isBusy(row.id) || rowIndex === rowData.length - 1"
        @click="handleResourceMove(row.id, 'down')"
      >
        <SouthIcon />
      </BaseButton>
      <BaseButton
        variant="icon"
        :disabled="isBusy(row.id) || rowIndex === 0"
        @click="handleResourceMove(row.id, 'up')"
      >
        <NorthIcon />
      </BaseButton>
      <BaseButton
        variant="icon"
        title="Переместить в самый верх"
        :disabled="isBusy(row.id) || rowIndex === 0"
        @click="handleResourceMove(row.id, 'up-top')"
      >
        <MoveTopIcon />
      </BaseButton>
      <BaseButton
        variant="icon"
        title="Редактировать"
        :disabled="isBusy(row.id)"
        :href="getGenerationFormUrl({ generationId: row.id })"
      >
        <EditIcon />
      </BaseButton>
      <BaseButton
        variant="icon"
        title="Удалить"
        :disabled="isBusy(row.id) || !row.canDelete"
        @click="handleResourceDelete(row.id)"
      >
        <DeleteIcon />
      </BaseButton>
    </template>
  </DataTable>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";

import {
  BaseButton,
  DataTable,
  DeleteIcon,
  EditIcon,
  getFilterParams,
  MoveTopIcon,
  NorthIcon,
  SouthIcon,
  useDataTable,
} from "@tager/admin-ui";
import { useResourceDelete, useResourceMove } from "@tager/admin-services";

import { GenerationInterface } from "@/typings/model";
import {
  deleteGeneration,
  getGenerationList,
  moveGeneration,
} from "@/services/requests";
import { getComplectationUpdateUrl, getGenerationFormUrl } from "@/utils/paths";
import { COLUMN_DEFS } from "@/modules/generations/GenerationList/GenerationList.helpers";
import { formatPrice } from "@/utils/formatter";

export default defineComponent({
  name: "BrandFormGenerations",
  components: {
    DataTable,
    SouthIcon,
    NorthIcon,
    EditIcon,
    DeleteIcon,
    BaseButton,
    MoveTopIcon,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const {
      fetchEntityList: fetchData,
      isLoading: isRowDataLoading,
      rowData: data,
      errorMessage,
    } = useDataTable<GenerationInterface>({
      fetchEntityList: () =>
        getGenerationList(
          getFilterParams({
            brand: props.id,
          })
        ),
      initialValue: [],
      resourceName: "Brand Generations List",
    });

    onMounted(() => {
      fetchData();
    });

    const { isMoving, handleResourceMove } = useResourceMove({
      moveResource: moveGeneration,
      resourceName: "Generations Move",
      onSuccess: fetchData,
    });

    const { isDeleting, handleResourceDelete } = useResourceDelete({
      deleteResource: deleteGeneration,
      resourceName: "Generations Delete",
      onSuccess: fetchData,
    });

    function isBusy(id: number): boolean {
      return isRowDataLoading.value || isMoving(id) || isDeleting(id);
    }

    return {
      columnDefs: COLUMN_DEFS,
      rowData: data,
      isRowDataLoading,
      isBusy,
      errorMessage: errorMessage,
      getComplectationUpdateUrl,
      formatPrice,

      handleResourceMove,
      handleResourceDelete,
    };
  },
  methods: { getGenerationFormUrl },
});
</script>

<style scoped lang="scss">
a {
  color: #007bff;
}

.filters {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.no-active {
  color: rgba(10, 10, 10, 0.35);
}

.stock {
  white-space: nowrap;

  .stock-section:not(:first-child) {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #eee;
  }

  .stock-title {
    display: block;
  }

  .stock-section-title {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .stock-price {
    display: block;
    margin-top: 10px;
  }

  .stock-link {
    margin-top: 15px;

    a {
      color: #007bff;
    }
  }
}
</style>
