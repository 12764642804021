import { ColumnDefinition } from "@tager/admin-ui";

import { ParamsCategoryModel } from "@/modules/params-categories/typings";
import { getParamsListUrl } from "@/modules/params/paths";

export const COLUMN_DEFS: Array<ColumnDefinition<ParamsCategoryModel>> = [
  {
    id: 1,
    name: "ID",
    field: "id",
    style: { width: "80px", textAlign: "center" },
    headStyle: { width: "80px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 2,
    name: "Название",
    field: "name",
    type: "name",
  },
  {
    id: 9,
    name: "",
    field: "relations",
    type: "relations",
    format: ({ row }) => {
      return [
        {
          label: "Параметры",
          quantity: row.paramsCount,
          to: getParamsListUrl(row.id),
        },
      ];
    },
    options: {
      allowZeroClick: true,
    },
    width: "160px",
  },
  {
    id: 10,
    name: "Действия",
    field: "actions",
    style: { width: "140px", textAlign: "center", whiteSpace: "nowrap" },
    headStyle: { width: "140px", textAlign: "center" },
  },
];
