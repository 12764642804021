import type { RouteRecordRaw } from "vue-router";

import { ROUTE_PATHS } from "@/constants/paths";
import { getLinks } from "@/constants/links";
import EngineForm from "@/modules/engines/EngineForm";

export const ENGINE_FORM_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.ENGINE_FORM,
  component: EngineForm,
  name: "Форма двигателя",
  meta: {
    getBreadcrumbs: (route, { t }) => [
      getLinks(t).HOME,
      {
        url: route.path,
        text: "Форма двигателя",
      },
    ],
  },
};
