<template>
  <DataTable
    :column-defs="columnDefs"
    :row-data="rowData"
    :loading="isRowDataLoading"
    :error-message="errorMessage"
    :use-search="false"
  >
    <template #cell(actions)="{ row }">
      <BaseButton
        variant="icon"
        title="Редактировать"
        :disabled="isBusy()"
        :href="getEngineUpdateUrl(row.id)"
      >
        <EditIcon />
      </BaseButton>
      <BaseButton
        variant="icon"
        title="Удалить"
        :disabled="isBusy(row.id)"
        @click="handleResourceDelete(row.id)"
      >
        <DeleteIcon />
      </BaseButton>
    </template>
  </DataTable>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";

import {
  BaseButton,
  ColumnDefinition,
  DataTable,
  DeleteIcon,
  EditIcon,
  getFilterParams,
  useDataTable,
} from "@tager/admin-ui";
import { useResourceDelete } from "@tager/admin-services";

import {
  deleteEngine,
  getEnginesList,
  getEngineUpdateUrl,
} from "@/modules/engines";
import { EngineModel } from "@/modules/engines/typings";
import {
  EngineType,
  getCarGearboxLabel,
  getCarWheelLabel,
  getEngineTypeLabel,
} from "@/enums";
import { getComplectationUpdateUrl } from "@/utils/paths";

const COLUMN_DEFS: Array<ColumnDefinition<EngineModel>> = [
  {
    id: 1,
    name: "ID",
    field: "id",
    style: { width: "80px", textAlign: "center" },
    headStyle: { width: "80px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 2,
    name: "Название",
    field: "name",
    type: "name",
    format: ({ row }) => ({
      adminLink: {
        text: row.name || "",
        url: getEngineUpdateUrl(row.id),
      },
      paramList: [
        { name: "Привод", value: getCarWheelLabel(row.wheel) },
        { name: "Коробка", value: getCarGearboxLabel(row.gearbox) },
      ],
    }),
  },
  {
    id: 3,
    name: "Двигатель",
    field: "type",
    format: ({ row }) => {
      const parts = [];

      if (row.volume) {
        parts.push(
          `${row.volume} ${row.type === EngineType.Electric ? "кВт/ч" : " л."}`
        );
      }

      if (row.power) {
        parts.push(`${row.power} л.c.`);
      }

      return getEngineTypeLabel(row.type) + ` (${parts.join(" / ")})`;
    },
  },
  {
    id: 4,
    name: "Комплектации",
    field: "complectations",
    type: "list",
    format: ({ row }) => {
      return row.complectations.map((item) => ({
        url: getComplectationUpdateUrl(item.id),
        label: item.name,
      }));
    },
  },

  {
    id: 5,
    name: "Действия",
    field: "actions",
    style: { width: "100px", textAlign: "center", whiteSpace: "nowrap" },
    headStyle: { width: "100px", textAlign: "center" },
  },
];

export default defineComponent({
  name: "GenerationEngines",
  components: {
    BaseButton,
    EditIcon,
    DeleteIcon,
    DataTable,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const {
      fetchEntityList: fetchData,
      isLoading: isRowDataLoading,
      rowData: data,
      errorMessage,
    } = useDataTable<EngineModel>({
      fetchEntityList: () =>
        getEnginesList(
          getFilterParams({
            generation: props.id,
          })
        ),
      initialValue: [],
      resourceName: "Generation Engine List",
    });

    onMounted(() => {
      fetchData();
    });

    const { isDeleting, handleResourceDelete } = useResourceDelete({
      deleteResource: deleteEngine,
      resourceName: "Engine",
      onSuccess: fetchData,
    });

    function isBusy(id: number): boolean {
      return isRowDataLoading.value || isDeleting(id);
    }

    return {
      columnDefs: COLUMN_DEFS,
      rowData: data,
      isRowDataLoading,
      isBusy,
      errorMessage: errorMessage,
      handleResourceDelete,
      getEngineUpdateUrl,
    };
  },
});
</script>
