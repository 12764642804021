import { createId, Nullable } from "@tager/admin-services";
import { OptionType, SingleFileInputValueType } from "@tager/admin-ui";

import { ComplectationFullInterface } from "@/typings/model";
import {
  ComplectationSavePayload,
  ComplectationCustomSavePayload,
} from "@/services/requests";

export interface FormValues {
  name: string;
  subtitle: string;
  image: Nullable<SingleFileInputValueType>;
  engines: Array<OptionType<number>>;
}

export function getComplectationFormValues(
  model: Nullable<ComplectationFullInterface>,
  engines: Array<OptionType<number>> = []
): FormValues {
  if (!model) {
    return {
      name: "",
      subtitle: "",
      image: null,
      engines: [],
    };
  }

  const engineIds = model.engines?.map((item) => +item.id) || [];

  return {
    name: model.name,
    subtitle: model.subtitle,
    image: model?.image ? { id: createId(), file: model.image } : null,
    engines: engines.filter((item) => engineIds.includes(item.value)),
  };
}

export function convertFormValuesToSavePayload(
  values: FormValues
): ComplectationSavePayload {
  return {
    subtitle: values.subtitle,
    image: values.image?.file?.id || null,
    engines: values.engines.map((item) => item.value),
  };
}

export function convertFormValuesToCustomSavePayload(
  values: FormValues
): ComplectationCustomSavePayload {
  return {
    generation: null,
    name: values.name,
    subtitle: values.subtitle,
    image: values.image?.file?.id || null,
    engines: values.engines.map((item) => item.value),
  };
}
