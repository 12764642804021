import { Nullable } from "@tager/admin-services";
import { OptionType } from "@tager/admin-ui";

import {
  EngineModelDetailed,
  EngineSavePayload,
} from "@/modules/engines/typings";
import {
  BatteryType,
  BatteryTypeOptions,
  EngineType,
  EngineTypeOptions,
  EuroStandard,
  EuroStandardOptions,
  Gearbox,
  GearboxOptions,
  Wheel,
  WheelOptions,
} from "@/enums";

export interface EngineFormValues {
  name: string;

  type: Nullable<OptionType<EngineType>>;
  wheel: Nullable<OptionType<Wheel>>;
  gearbox: Nullable<OptionType<Gearbox>>;
  euroEmissionsStandard: Nullable<OptionType<EuroStandard>>;
  electricBatteryType: Nullable<OptionType<BatteryType>>;

  volume: Nullable<number>;
  power: Nullable<number>;
  gearsCount: Nullable<number>;
  maxTorque: Nullable<number>;
  maxSpeed: Nullable<number>;
  acceleration0_100: Nullable<number>;
  consumptionCity: Nullable<number>;
  consumptionHighway: Nullable<number>;
  consumptionMixed: Nullable<number>;
  co2EmissionsCity: Nullable<number>;
  co2EmissionsHighway: Nullable<number>;
  co2EmissionsMixed: Nullable<number>;
  curbWeight: Nullable<number>;
  maxAllowableWeight: Nullable<number>;
  electricWltpShortage: Nullable<number>;
  electricWltpRange: Nullable<number>;
  electricChargingTimeDC: Nullable<number>;
  electricChargingTimeAC: Nullable<number>;
  electricEnginesCount: Nullable<number>;

  complectations: Array<OptionType<number>>;
}

export function getEngineFormValues(
  model: Nullable<EngineModelDetailed>
): EngineFormValues {
  return {
    name: model?.name || "",

    type: EngineTypeOptions.find((item) => item.value === model?.type) || null,
    wheel: WheelOptions.find((item) => item.value === model?.wheel) || null,
    gearbox:
      GearboxOptions.find((item) => item.value === model?.gearbox) || null,
    euroEmissionsStandard:
      EuroStandardOptions.find(
        (item) => item.value === model?.euroEmissionsStandard
      ) || null,
    electricBatteryType:
      BatteryTypeOptions.find(
        (item) => item.value === model?.electricBatteryType
      ) || null,

    volume: model?.volume || null,
    power: model?.power || null,
    gearsCount: model?.gearsCount || null,
    maxTorque: model?.maxTorque || null,
    maxSpeed: model?.maxSpeed || null,
    acceleration0_100: model?.acceleration0_100 || null,
    consumptionCity: model?.consumptionCity || null,
    consumptionHighway: model?.consumptionHighway || null,
    consumptionMixed: model?.consumptionMixed || null,
    co2EmissionsCity: model?.co2EmissionsCity || null,
    co2EmissionsHighway: model?.co2EmissionsHighway || null,
    co2EmissionsMixed: model?.co2EmissionsMixed || null,
    curbWeight: model?.curbWeight || null,
    maxAllowableWeight: model?.maxAllowableWeight || null,
    electricWltpShortage: model?.electricWltpShortage || null,
    electricWltpRange: model?.electricWltpRange || null,
    electricChargingTimeDC: model?.electricChargingTimeDC || null,
    electricChargingTimeAC: model?.electricChargingTimeAC || null,
    electricEnginesCount: model?.electricEnginesCount || null,

    complectations:
      model?.complectations.map((item) => ({
        value: item.id,
        label: item.name,
      })) || [],
  };
}

export function convertEngineFormValuesToSavePayload(
  values: EngineFormValues,
  generationId: number
): EngineSavePayload {
  console.log(values.complectations.map((item) => item.value));

  const isElectric = values.type?.value === EngineType.Electric;

  return {
    generation: generationId,
    name: values.name,

    type: values.type?.value || null,
    wheel: values.wheel?.value || null,
    gearbox: values.gearbox?.value || null,
    euroEmissionsStandard: values.euroEmissionsStandard?.value || null,
    electricBatteryType: isElectric
      ? values.electricBatteryType?.value || null
      : null,

    volume: values.volume || null,
    power: values.power || null,
    gearsCount: values.gearsCount || null,
    maxTorque: values.maxTorque || null,
    maxSpeed: values.maxSpeed || null,
    acceleration0_100: values.acceleration0_100 || null,
    consumptionCity: values.consumptionCity || null,
    consumptionHighway: values.consumptionHighway || null,
    consumptionMixed: values.consumptionMixed || null,
    co2EmissionsCity: values.co2EmissionsCity || null,
    co2EmissionsHighway: values.co2EmissionsHighway || null,
    co2EmissionsMixed: values.co2EmissionsMixed || null,
    curbWeight: values.curbWeight || null,
    maxAllowableWeight: values.maxAllowableWeight || null,
    electricWltpShortage: isElectric
      ? values.electricWltpShortage || null
      : null,
    electricWltpRange: isElectric ? values.electricWltpRange || null : null,
    electricChargingTimeDC: isElectric
      ? values.electricChargingTimeDC || null
      : null,
    electricChargingTimeAC: isElectric
      ? values.electricChargingTimeAC || null
      : null,
    electricEnginesCount: isElectric
      ? values.electricEnginesCount || null
      : null,

    complectations: values.complectations.map((item) => item.value),
  };
}
