import { OptionType } from "@tager/admin-ui";

export enum BatteryType {
  LithiumIon = "LITHIUM_ION",
  LithiumIron = "LITHIUM_IRON",
  LithiumNickel = "LITHIUM_NICKEL",
  LithiumPolymeric = "LITHIUM_POLYMERIC",
  MetalAir = "METAL_AIR",
}

const CarBatteryTypesList: Array<{ id: BatteryType; label: string }> = [
  { id: BatteryType.LithiumIon, label: "Литий-ионная" },
  { id: BatteryType.LithiumIron, label: "Литий-железо-фосфатная" },
  {
    id: BatteryType.LithiumNickel,
    label: "Литий-никель-марганец-кобальт-оксидная",
  },
  { id: BatteryType.LithiumPolymeric, label: "Литий-полимерная" },
  { id: BatteryType.MetalAir, label: "Метало-воздушная" },
];

export function getBatteryTypeLabel(engineType: BatteryType): string {
  return (
    CarBatteryTypesList.find((item) => item.id === engineType)?.label ||
    "Неизвестный"
  );
}
const createOptionTypes = (
  items: BatteryType[]
): Array<OptionType<BatteryType>> =>
  items.map((item) => ({ value: item, label: getBatteryTypeLabel(item) }));

export const BatteryTypeOptions: Array<OptionType<BatteryType>> =
  createOptionTypes([
    BatteryType.LithiumIon,
    BatteryType.LithiumIron,
    BatteryType.LithiumNickel,
    BatteryType.LithiumPolymeric,
    BatteryType.MetalAir,
  ]);
