import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6a9be6f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "breadcrumbs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_FieldValue = _resolveComponent("FieldValue")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FormFieldFileInput = _resolveComponent("FormFieldFileInput")!
  const _component_FormFieldMultiSelect = _resolveComponent("FormFieldMultiSelect")!
  const _component_FormFooter = _resolveComponent("FormFooter")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    title: _ctx.pageTitle,
    "is-content-loading": _ctx.isContentLoading
  }, {
    topRight: _withCtx(() => [
      _createElementVNode("ul", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbs, (item) => {
          return (_openBlock(), _createElementBlock("li", {
            key: item.url
          }, [
            _createVNode(_component_RouterLink, {
              to: item.url
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.label), 1)
              ]),
              _: 2
            }, 1032, ["to"])
          ]))
        }), 128))
      ])
    ]),
    footer: _withCtx(() => [
      (!_ctx.isContentLoading)
        ? (_openBlock(), _createBlock(_component_FormFooter, {
            key: 0,
            "back-href": _ctx.backButtonUrl,
            "is-submitting": _ctx.isSubmitting,
            "is-creation": _ctx.isCreation,
            "can-create-another": true,
            onSubmit: _ctx.submitForm
          }, null, 8, ["back-href", "is-submitting", "is-creation", "onSubmit"]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      (!_ctx.isContentLoading)
        ? (_openBlock(), _createElementBlock("form", {
            key: 0,
            novalidate: "",
            onSubmit: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"]))
          }, [
            _createVNode(_component_FieldValue, {
              label: "Поколение",
              value: _ctx.generationName
            }, null, 8, ["value"]),
            (_ctx.isCustom)
              ? (_openBlock(), _createBlock(_component_FormField, {
                  key: 0,
                  value: _ctx.values.name,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values.name) = $event)),
                  name: "name",
                  error: _ctx.errors.name,
                  label: "Название"
                }, null, 8, ["value", "error"]))
              : _createCommentVNode("", true),
            _createVNode(_component_FormField, {
              value: _ctx.values.subtitle,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.values.subtitle) = $event)),
              name: "subtitle",
              error: _ctx.errors.subtitle,
              label: "Подзаголовок"
            }, null, 8, ["value", "error"]),
            _createVNode(_component_FormFieldFileInput, {
              value: _ctx.values.image,
              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.values.image) = $event)),
              error: _ctx.errors.image,
              label: "Картинка (245 x 180)",
              name: "image",
              "file-type": "image",
              scenario: "complectation"
            }, null, 8, ["value", "error"]),
            _createVNode(_component_FormFieldMultiSelect, {
              "selected-options": _ctx.values.engines,
              "onUpdate:selectedOptions": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.values.engines) = $event)),
              disabled: _ctx.isEnginesLoading,
              options: _ctx.engineOptions,
              label: "Двигатели",
              searchable: false
            }, null, 8, ["selected-options", "disabled", "options"])
          ], 32))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "is-content-loading"]))
}