<template>
  <Page
    :title="pageTitle"
    :header-buttons="[
      {
        text: 'Новый параметр',
        href: getParamsCreateUrl({ category: category?.id }),
      },
    ]"
  >
    <DataTable
      :column-defs="columnDefs"
      :row-data="rowData"
      :loading="isRowDataLoading"
      :error-message="errorMessage"
      :search-query="searchQuery"
      :pagination="{
        pageNumber,
        pageCount,
        pageSize,
        disabled: isRowDataLoading,
      }"
      @change="handleChange"
    >
      <template #cell(actions)="{ row, totalRowIndex, totalCount }">
        <BaseButton
          variant="icon"
          :disabled="isBusy(row.id) || totalRowIndex === totalCount - 1"
          @click="handleResourceMove(row.id, 'down')"
        >
          <SouthIcon />
        </BaseButton>
        <BaseButton
          variant="icon"
          :disabled="isBusy(row.id) || totalRowIndex === 0"
          @click="handleResourceMove(row.id, 'up')"
        >
          <NorthIcon />
        </BaseButton>
        <BaseButton
          variant="icon"
          title="Переместить в самый верх"
          :disabled="isBusy(row.id) || totalRowIndex === 0"
          @click="handleResourceMove(row.id, 'up-top')"
        >
          <MoveTopIcon />
        </BaseButton>
        <BaseButton
          variant="icon"
          title="Редактировать"
          :disabled="isBusy(row.id)"
          :href="getParamsUpdateUrl(row.id)"
        >
          <EditIcon />
        </BaseButton>
        <BaseButton
          variant="icon"
          title="Удалить"
          :disabled="isBusy(row.id) || !row.canDelete"
          @click="handleResourceDelete(row.id)"
        >
          <DeleteIcon />
        </BaseButton>
      </template>
    </DataTable>
  </Page>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  useDataTable,
  DataTable,
  BaseButton,
  EditIcon,
  SouthIcon,
  MoveTopIcon,
  NorthIcon,
  DeleteIcon,
  getFilterParams,
} from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";
import {
  useResource,
  useResourceDelete,
  useResourceMove,
} from "@tager/admin-services";

import { getParamsCategory } from "@/modules/params-categories/requests";
import { getParamsCategoryCreateUrl } from "@/modules/params-categories/paths";
import {
  deleteParam,
  getParamsList,
  moveParam,
} from "@/modules/params/requests";
import { getParamsCreateUrl, getParamsUpdateUrl } from "@/modules/params/paths";
import { ParamModel } from "@/modules/params/typings";
import { COLUMN_DEFS } from "@/modules/params/ParamsList/ParamsList.helpers";

export default defineComponent({
  name: "ParamsList",
  components: {
    DeleteIcon,
    NorthIcon,
    MoveTopIcon,
    SouthIcon,
    DataTable,
    Page,
    BaseButton,
    EditIcon,
  },
  setup() {
    const route = useRoute();
    const categoryId = computed<number>(() => +route.params.category);

    const [fetchCategory, { data: category, loading: isCategoryLoading }] =
      useResource({
        fetchResource: () => getParamsCategory(categoryId.value),
        initialValue: null,
        resourceName: "Params Category",
      });

    const {
      fetchEntityList,
      isLoading,
      rowData,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<ParamModel>({
      fetchEntityList: (params) =>
        getParamsList({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
          ...getFilterParams({
            category: String(categoryId.value),
          }),
        }),
      initialValue: [],
      resourceName: "Список параметров",
    });

    const { isMoving, handleResourceMove } = useResourceMove({
      moveResource: moveParam,
      resourceName: "Move Param",
      onSuccess: fetchEntityList,
    });

    const { isDeleting, handleResourceDelete } = useResourceDelete({
      deleteResource: deleteParam,
      resourceName: "Delete Param",
      onSuccess: fetchEntityList,
    });

    function isBusy(id: number): boolean {
      return isDeleting(id) || isMoving(id) || isLoading.value;
    }

    onMounted(() => {
      fetchCategory();
    });

    const isRowDataLoading = computed<boolean>(
      () => isLoading.value || isCategoryLoading.value
    );

    const pageTitle = computed<string>(() => {
      if (isCategoryLoading.value) {
        return "Загрузка...";
      }

      return "Параметры - " + category.value?.name;
    });

    return {
      columnDefs: COLUMN_DEFS,
      rowData,
      isRowDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      isBusy,

      handleResourceDelete,
      getParamsCategoryCreateUrl,
      handleResourceMove,
      getParamsUpdateUrl,

      pageTitle,
      category,
    };
  },
  methods: { getParamsCreateUrl },
});
</script>
