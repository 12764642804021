import { compile } from "path-to-regexp";

import { ROUTE_PATHS } from "@/constants/paths";

export function getParamsCategoryListUrl(): string {
  return compile(ROUTE_PATHS.PARAMS_CATEGORIES_LIST)();
}

export function getParamsCategoryCreateUrl() {
  return compile(ROUTE_PATHS.PARAMS_CATEGORIES_FORM)({
    id: "create",
  });
}

export function getParamsCategoryUpdateUrl(id: number) {
  return compile(ROUTE_PATHS.PARAMS_CATEGORIES_FORM)({ id: String(id) });
}
