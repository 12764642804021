import { OptionType } from "@tager/admin-ui";

export enum ParamType {
  Boolean = "BOOLEAN",
  Numeric = "NUMERIC",
  Text = "TEXT",
  Select = "SELECT",
}

export const getParamTypeLabel = (value: string | null): string => {
  switch (value) {
    case ParamType.Text:
      return "Текст";
    case ParamType.Boolean:
      return "Да / Нет";
    case ParamType.Numeric:
      return "Число";
    case ParamType.Select:
      return "Выбор";
    default:
      return "Неизвестный";
  }
};

export const ParamTypeOptions: Array<OptionType<ParamType>> = [
  { value: ParamType.Boolean, label: getParamTypeLabel(ParamType.Boolean) },
  { value: ParamType.Numeric, label: getParamTypeLabel(ParamType.Numeric) },
  { value: ParamType.Text, label: getParamTypeLabel(ParamType.Text) },
  { value: ParamType.Select, label: getParamTypeLabel(ParamType.Select) },
];
