import { TFunction } from "i18next";

import { LinkType } from "@tager/admin-ui";
import { getAdminListUrl, getRoleListUrl } from "@tager/admin-administrators";
import {
  getCommandsListUrl,
  getCronLogsUrl,
  getCommandsLogsUrl,
} from "@tager/admin-cron";

import {
  getBrandListUrl,
  getGenerationListUrl,
  getModelListUrl,
} from "@/utils/paths";
import { getParamsCategoryListUrl } from "@/modules/params-categories/paths";

type LinkKey =
  | "HOME"
  | "BRAND_LIST"
  | "MODEL_LIST"
  | "GENERATION_LIST"
  | "PARAMS_CATEGORIES_LIST"
  | "CRON_COMMANDS"
  | "CRON_LOGS"
  | "CRON_COMMANDS_LOGS"
  | "ADMIN_ROLES"
  | "ADMIN_ADMINS";

export const getLinks = (
  t: TFunction
): Readonly<Record<LinkKey, LinkType>> => ({
  HOME: {
    url: "/",
    text: "Главная",
  },
  BRAND_LIST: {
    url: getBrandListUrl(),
    text: "Бренды",
  },
  MODEL_LIST: {
    url: getModelListUrl(),
    text: "Модели",
  },
  GENERATION_LIST: {
    url: getGenerationListUrl(),
    text: "Поколения",
  },
  PARAMS_CATEGORIES_LIST: {
    url: getParamsCategoryListUrl(),
    text: "Параметры",
  },
  CRON_COMMANDS: {
    url: getCommandsListUrl(),
    text: "Команды",
  },
  CRON_COMMANDS_LOGS: {
    url: getCommandsLogsUrl(),
    text: "Команды - Логи",
  },
  CRON_LOGS: {
    url: getCronLogsUrl(),
    text: "Cron Логи",
  },
  ADMIN_ROLES: {
    url: getRoleListUrl(),
    text: "Роли",
  },
  ADMIN_ADMINS: {
    url: getAdminListUrl(),
    text: "Администраторы",
  },
});
