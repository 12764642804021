<template>
  <DataTable
    :column-defs="columnDefs"
    :row-data="rowData"
    :loading="isRowDataLoading"
    :error-message="errorMessage"
    :use-search="false"
  >
    <template #cell(stock)="{ row }">
      <div :class="['stock']">
        <div v-if="row.stockQuantity > 0">
          <span class="stock-title">{{ row.stockQuantity }} авто</span>
          <span class="stock-price"
            >от {{ formatPrice(row.stockMinPrice) }} BYN<br />до
            {{ formatPrice(row.stockMaxPrice) }} BYN</span
          >
        </div>
        <span v-else> - </span>
      </div>
    </template>

    <template #cell(actions)="{ row, rowIndex }">
      <BaseButton
        variant="icon"
        :disabled="isBusy(row.id) || rowIndex === rowData.length - 1"
        @click="handleResourceMove(row.id, 'down')"
      >
        <SouthIcon />
      </BaseButton>
      <BaseButton
        variant="icon"
        :disabled="isBusy(row.id) || rowIndex === 0"
        @click="handleResourceMove(row.id, 'up')"
      >
        <NorthIcon />
      </BaseButton>
      <BaseButton
        variant="icon"
        title="Переместить в самый верх"
        :disabled="isBusy(row.id) || rowIndex === 0"
        @click="handleResourceMove(row.id, 'up-top')"
      >
        <MoveTopIcon />
      </BaseButton>
      <BaseButton
        variant="icon"
        title="Редактировать"
        :disabled="isBusy()"
        :href="getComplectationUpdateUrl(row.id)"
      >
        <EditIcon />
      </BaseButton>
      <BaseButton
        variant="icon"
        title="Удалить"
        :disabled="isBusy(row.id) || !row.canDelete"
        @click="handleResourceDelete(row.id)"
      >
        <DeleteIcon />
      </BaseButton>
    </template>
  </DataTable>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";

import {
  BaseButton,
  ColumnDefinition,
  DataTable,
  DeleteIcon,
  EditIcon,
  getFilterParams,
  MoveTopIcon,
  NorthIcon,
  SouthIcon,
  useDataTable,
} from "@tager/admin-ui";
import { useResourceDelete, useResourceMove } from "@tager/admin-services";

import { ComplectationInterface } from "@/typings/model";
import {
  deleteComplectation,
  getComplectationList,
  moveComplectation,
} from "@/services/requests";
import { getComplectationUpdateUrl } from "@/utils/paths";
import { getEngineUpdateUrl } from "@/modules/engines";

const COLUMN_DEFS: Array<ColumnDefinition<ComplectationInterface>> = [
  {
    id: 1,
    name: "ID",
    field: "id",
    style: { width: "80px", textAlign: "center" },
    headStyle: { width: "80px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 1,
    name: "API ID",
    field: "apiId",
    style: { width: "80px", textAlign: "center" },
    headStyle: { width: "80px", textAlign: "center", whiteSpace: "nowrap" },
  },
  {
    id: 2,
    name: "Название",
    field: "name",
    type: "name",
    format: ({ row }) => ({
      adminLink: {
        text: row.name,
        url: getComplectationUpdateUrl(row.id),
      },
      paramList: [
        { name: "Бренд", value: row.brand },
        { name: "Модель", value: row.model },
        { name: "Поколение", value: row.generation },
      ],
    }),
  },
  {
    id: 3,
    name: "Картинка",
    field: "image",
    type: "image",
    width: "180px",
  },
  {
    id: 7,
    name: "Наличие",
    field: "stock",
    width: "180px",
  },
  {
    id: 4,
    name: "Двигатели",
    field: "engines",
    type: "list",
    format: ({ row }) => {
      return row.engines.map((item) => ({
        url: getEngineUpdateUrl(item.id),
        label: item.name,
      }));
    },
  },
  {
    id: 8,
    name: "Действия",
    field: "actions",
    style: { width: "100px", textAlign: "center", whiteSpace: "nowrap" },
    headStyle: { width: "100px", textAlign: "center" },
  },
];

export default defineComponent({
  name: "GenerationComplectations",
  components: {
    BaseButton,
    EditIcon,
    DeleteIcon,
    DataTable,
    MoveTopIcon,
    NorthIcon,
    SouthIcon,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const {
      fetchEntityList: fetchData,
      isLoading: isRowDataLoading,
      rowData: data,
      errorMessage,
    } = useDataTable<ComplectationInterface>({
      fetchEntityList: () =>
        getComplectationList(
          getFilterParams({
            generation: props.id,
          })
        ),
      initialValue: [],
      resourceName: "Generation Complectation List",
    });

    onMounted(() => {
      fetchData();
    });

    const { isDeleting, handleResourceDelete } = useResourceDelete({
      deleteResource: deleteComplectation,
      resourceName: "Complectation Delete",
      onSuccess: fetchData,
    });

    const { isMoving, handleResourceMove } = useResourceMove({
      moveResource: moveComplectation,
      resourceName: "Complectation Move",
      onSuccess: fetchData,
    });

    function isBusy(id: number): boolean {
      return isRowDataLoading.value || isDeleting(id) || isMoving(id);
    }

    return {
      columnDefs: COLUMN_DEFS,
      rowData: data,
      isRowDataLoading,
      isBusy,
      errorMessage: errorMessage,
      handleResourceDelete,
      getComplectationUpdateUrl,
      handleResourceMove,
    };
  },
});
</script>
