<template>
  <Spinner v-if="loading" />
  <template v-else>
    <template v-if="complectations.length === 0"> Нет комплектаций </template>
    <template v-else>
      <ToggleSection
        v-for="(item, categoryIndex) in categories"
        :key="item.id"
        :label="item.name"
      >
        <table class="params-table">
          <thead>
            <tr>
              <th>Параметр</th>
              <th
                v-for="complectation in complectations"
                :key="complectation.id"
              >
                {{ complectation.name }}
              </th>
              <th>&nbsp;</th>
            </tr>
            <tr v-for="(row, index) in rows[item.id]" :key="index">
              <td>
                <BaseSelect
                  v-model:value="row.param"
                  :options="paramsByCategories[item.id]"
                  :searchable="true"
                  :clearable="false"
                />
              </td>
              <td
                v-for="(complectation, cIndex) in complectations"
                :key="complectation.id"
              >
                <span v-if="!paramsValues[categoryIndex][index]?.type">-</span>
                <template v-else>
                  <BaseTextArea
                    v-if="paramsValues[categoryIndex][index]?.type === 'TEXT'"
                    v-model:value="row.values[cIndex]"
                    type="text"
                    rows="3"
                  />
                  <BaseInput
                    v-if="
                      paramsValues[categoryIndex][index]?.type === 'NUMERIC'
                    "
                    v-model:value="row.values[cIndex]"
                    type="number"
                  />
                  <OptionsSwitcherTrueFalse
                    v-if="
                      paramsValues[categoryIndex][index]?.type === 'BOOLEAN'
                    "
                    v-model:value="row.values[cIndex]"
                  />
                  <BaseSelect
                    v-if="paramsValues[categoryIndex][index]?.type === 'SELECT'"
                    v-model:value="row.values[cIndex]"
                    :options="
                  (paramsValues[categoryIndex][index]?.options || []).map(
                    (item:string) => ({ value: item, label: item })
                  )
                "
                  />
                </template>
              </td>
              <td>
                <BaseButton
                  variant="icon"
                  :disabled="index === rows[item.id].length - 1"
                  @click="() => onMoveClick(item.id, index, 'down')"
                >
                  <SouthIcon />
                </BaseButton>
                <BaseButton
                  variant="icon"
                  :disabled="index === 0"
                  @click="() => onMoveClick(item.id, index, 'up')"
                >
                  <NorthIcon />
                </BaseButton>
                <BaseButton
                  variant="icon"
                  @click="() => onDeleteClick(item.id, index)"
                >
                  <DeleteIcon />
                </BaseButton>
              </td>
            </tr>
          </thead>
        </table>
        <br /><BaseButton @click="() => onAddClick(item.id)"
          >Добавить</BaseButton
        >
      </ToggleSection>
    </template>
  </template>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  watch,
} from "vue";

import {
  getFilterParams,
  ToggleSection,
  Spinner,
  BaseButton,
  BaseSelect,
  BaseTextArea,
  OptionType,
  DeleteIcon,
  BaseInput,
  OptionsSwitcherTrueFalse,
  SouthIcon,
  NorthIcon,
} from "@tager/admin-ui";
import { Nullable, useResource } from "@tager/admin-services";

import { ComplectationInterface } from "@/typings/model";
import { getComplectationList } from "@/services/requests";
import { getParamsCategoriesList } from "@/modules/params-categories/requests";
import { ParamsCategoryModel } from "@/modules/params-categories/typings";
import { ParamModel } from "@/modules/params/typings";
import { getParamsList } from "@/modules/params/requests";
import { OptionRow } from "@/modules/generations/GenerationForm/GenerationForm.helpers";

type OptionRowValue = {
  param: Nullable<OptionType<number>>;
  values: any[];
};

type FormValue = Record<number, OptionRowValue[]>;

const convertOptionRowsToFormValue = (
  data: OptionRow[],
  {
    categories,
    params,
    complectations,
  }: {
    categories: Array<ParamsCategoryModel>;
    params: Array<ParamModel>;
    complectations: Array<ComplectationInterface>;
  }
): FormValue => {
  const result: FormValue = {};

  categories.forEach((category) => {
    result[category.id] = [];
    data.forEach((option) => {
      const param = params.find((item) => item.id === option.paramId);
      if (param && param.category.id === category.id) {
        result[category.id].push({
          param: {
            value: param.id,
            label: param.name,
          },
          values: complectations.map((complectation) => {
            const value = option.values.find(
              (item) => item.complectationId === complectation.id
            )?.value;

            if (param.type === "SELECT") {
              return value && param.options.includes(value)
                ? { value: value, label: value }
                : value;
            }

            if (param.type === "BOOLEAN") {
              return String(value) === "1";
            }

            return value;
          }),
        });
      }
    });
  });

  return result;
};

export default defineComponent({
  name: "GenerationOptions",
  components: {
    NorthIcon,
    SouthIcon,
    ToggleSection,
    Spinner,
    BaseButton,
    BaseSelect,
    DeleteIcon,
    BaseInput,
    BaseTextArea,
    OptionsSwitcherTrueFalse,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    value: {
      type: Array as PropType<Array<OptionRow>>,
      default: null,
    },
  },
  emits: ["update:value"],
  setup(props, context) {
    const initialized = ref<boolean>(false);

    const [
      fetchComplectations,
      { loading: isComplectationsLoading, data: complectations },
    ] = useResource<ComplectationInterface[]>({
      fetchResource: () =>
        getComplectationList(
          getFilterParams({
            generation: props.id,
          })
        ),
      initialValue: [],
      resourceName: "Complectations",
    });

    const [
      fetchCategories,
      { loading: isCategoriesLoading, data: categories },
    ] = useResource<ParamsCategoryModel[]>({
      fetchResource: () => getParamsCategoriesList(),
      initialValue: [],
      resourceName: "Categories",
    });

    const [fetchParams, { loading: isParamsLoading, data: params }] =
      useResource<ParamModel[]>({
        fetchResource: () => getParamsList(),
        initialValue: [],
        resourceName: "Params",
      });

    const loading = computed<boolean>(
      () =>
        isCategoriesLoading.value ||
        isParamsLoading.value ||
        isComplectationsLoading.value
    );

    onMounted(() => {
      fetchComplectations();
      fetchCategories();
      fetchParams();
    });

    const rows = ref<FormValue>({});
    watch([categories, complectations, params], () => {
      if (!loading.value) {
        rows.value = convertOptionRowsToFormValue(props.value, {
          categories: categories.value,
          params: params.value,
          complectations: complectations.value,
        });
        initialized.value = true;
      }
    });

    const onAddClick = (categoryId: number) => {
      if (!(categoryId in rows.value)) {
        rows.value = { ...rows.value, [categoryId]: [] };
      }

      rows.value = {
        ...rows.value,
        [categoryId]: [
          ...rows.value[categoryId],
          {
            param: null,
            values: complectations.value.map(() => null),
          },
        ],
      };
    };

    const onDeleteClick = (categoryId: number, index: number) => {
      if (!(categoryId in rows.value)) return;

      rows.value = {
        ...rows.value,
        [categoryId]:
          rows.value[categoryId].length === 1 && index === 0
            ? []
            : [...rows.value[categoryId].splice(index, 1)],
      };
    };

    const onMoveClick = (
      categoryId: number,
      index: number,
      direction: "up" | "down"
    ) => {
      if (!(categoryId in rows.value)) return;

      const row: OptionRowValue[] = rows.value[categoryId];

      const before =
        direction === "up" ? row.slice(0, index - 1) : row.slice(0, index);
      const after =
        direction === "up" ? row.slice(index + 1) : row.slice(index + 2);

      const newRow: OptionRowValue[] =
        direction === "up"
          ? [...before, row[index], row[index - 1], ...after]
          : [...before, row[index + 1], row[index], ...after];

      rows.value = {
        ...rows.value,
        [categoryId]: newRow,
      };
    };

    const paramsByCategories = computed(() => {
      const result: Record<number, OptionType<number>[]> = {};

      params.value.forEach((param) => {
        if (!param.category) return;

        if (!(param.category.id in result)) {
          result[param.category.id] = [];
        }

        result[param.category.id].push({
          value: param.id,
          label: param.name,
        });
      });

      return result;
    });

    const paramsValues = computed<Array<Array<ParamModel | null>>>(() => {
      const result: Array<Array<ParamModel | null>> = [];

      categories.value.forEach((category) => {
        result.push(
          rows.value[category.id]?.map((item) => {
            return (
              params.value.find((_item) => _item.id === item.param?.value) ||
              null
            );
          }) || []
        );
      });

      return result;
    });

    const getFormattedValue = (type: string, value: any): any => {
      switch (type) {
        case "NUMERIC":
          return +value;
        case "SELECT":
          return (value as OptionType<string>)?.value || null;
        case "BOOLEAN":
          return !!value;
        default:
          return String(value);
      }
    };

    const resultValue = computed(() => {
      if (!initialized.value) return props.value;

      const result: OptionRow[] = [];

      categories.value?.forEach((category) => {
        rows.value[category.id]?.forEach((param) => {
          const paramModel = params.value.find(
            (item) => item.id === param.param?.value
          );
          if (paramModel) {
            result.push({
              paramId: paramModel.id,
              values: complectations.value.map((complectation, index) => ({
                complectationId: complectation.id,
                value: getFormattedValue(paramModel.type, param.values[index]),
              })),
            });
          }
        });
      });

      return result;
    });

    watch(resultValue, () => {
      context.emit("update:value", resultValue.value);
    });

    return {
      loading,
      complectations,
      categories,
      paramsByCategories,
      onAddClick,
      onDeleteClick,
      onMoveClick,
      rows,
      paramsValues,
    };
  },
});
</script>

<style lang="scss">
.params-table {
  td,
  th {
    &:first-child {
      width: 300px;
    }
    &:last-child {
      width: 140px;
    }
  }
}
</style>
